import React from 'react';
import { css } from '@emotion/react';
import { colors } from '../../styles';
import { scrollToAnchor, trackEvent } from '../../utils';

const style = css`
  display: block;
  position: relative;
  overflow: hidden;
  width: 83px;
  height: 83px;
  border-radius: 50%;
  border-width: 3px;
  border-style: solid;
  border-color: ${colors.wtRed};
  .arrow-wrap {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    animation: boing 6s infinite;
  }
  svg {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: 0.2s transform ease-out;
  }

  @media (hover: hover) {
    &:hover {
      .arrow-wrap {
        animation: none;
      }
      svg {
        transform: translate(-50%, -25%);
      }
    }
  }

  @keyframes boing {
    0%, 5%, 15%, 25%, 100% {transform: translateY(0);}
	  10% {transform: translateY(6px);}
	  20% {transform: translateY(3px);}
  }
  
`;

const IconDownArrow = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22.232" height="26.744" viewBox="0 0 22.232 26.744">
      <path d="M18278.336,13792.742v-19.99l-6.4,11.131-2.934-1.7,9.314-16.187.283.166h3.035l.285-.166,9.314,16.187-2.934,1.7-6.574-11.426v20.285Z" transform="translate(18291.23 13792.742) rotate(180)" fill="#de0c23"/>
    </svg>
  )
}

const AnchorScrollButton = (props) => {
  const { anchor, ...rest } = props;

  const onClick = () => {
    trackEvent('scroll-to-anchor', anchor);
    scrollToAnchor(anchor);
  }

  return (
    <button css={style} {...rest} onClick={onClick} aria-label={`go to ${anchor}`}>
      <div className='arrow-wrap'>
        <IconDownArrow />
      </div>
    </button>
  )
}

export default AnchorScrollButton;
