import React from 'react';
import { css } from '@emotion/react';
import AnimatedBlock, { animClass, animTypes } from '../AnimatedBlock';
import ContentWrap from '../ContentWrap';
import classNames from 'classnames';
import SectionTitle from '../ui/SectionTitle';
import { scrollbars, breakpoints } from '../../styles';
import BookThumb from '../ui/BookThumb';


const style = css`
  margin-bottom: 120px;
  .book-list--top {
    display: block;
    h3 {
      margin-bottom: 24px;
    }
  }

  .book-list--list-wrap {
    display: block;
    position: relative;
    width: 100%;
    min-height: 200px;
    overflow: hidden;
    overflow-x: auto;
    ${scrollbars.h};
  }

  .book-list--scroll {
    display: flex;
    flex-direction: row;
    padding-bottom: 40px;
  }
  
  @media(max-width: ${breakpoints.mobile}px) {
    margin-bottom: 64px;
  }
`;

const BookListBlock = ({ content, items, setModalContent }) => {
  const { section_title, title } = content;

  return (
    <AnimatedBlock css={style}>
      <ContentWrap>
        <div className='book-list--top'>
          { section_title && (
            <SectionTitle title={section_title} animDelay={0} />
          )}
          { title && (
            <h3 className={classNames(animClass)} data-anim-type={animTypes.SLIDE_UP} data-anim-delay={0.2} dangerouslySetInnerHTML={{__html:title.text}} />
          )}
        </div>
        <div className='book-list--list-wrap'>
          <div className='book-list--scroll'>
            { items.map((d,i) => {
              const del = 0.6 + i * 0.2;
              return (
                <BookThumb setModalContent={setModalContent} key={i} animDelay={del} {...d} />
              )
            })}
          </div>
        </div>
      </ContentWrap>
    </AnimatedBlock>
  )
}

export default BookListBlock;
