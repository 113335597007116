import React from 'react';
import { Link } from 'gatsby';

export const LinkCTA = (props) => {
  const { css, children, link, target, onClick, ...rest } = props;
  const dest = link === 'home' ? '/' : `/${link}`;
  return (
    <Link css={css} to={dest} {...rest}>
      {children}
    </Link>
  )
}

export const ExternalCTA = (props) => {
  const { css, children, link, onClick, target='_blank', ...rest } = props;
  
  return (
    <a css={css} href={link} rel='noopener noreferrer' target={target} onClick={onClick} {...rest}>
      {children}
    </a>
  )
}

export const ClickCTA = (props) => {
  const { css, children, link, target, onClick, ...rest } = props;

  return (
    <button css={css} {...rest} onClick={onClick}>
      {children}
    </button>
  )
}

export const NoCTA = (props) => {
  const { css, children, ...rest } = props;

  return (
    <div css={css} {...rest}>
      {children}
    </div>
  )
}
