import './src/styles/global.css';

import * as React from 'react'

import {
  PrismicPreviewProvider,
} from 'gatsby-plugin-prismic-previews'

import { linkResolver } from './src/utils/linkResolver';
import Page from './src/templates/page';

const repositoryConfigs = [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPOSITORY_NAME,
    linkResolver: linkResolver,
    componentResolver: {
      page: Page,
    },
  },
];

export const wrapRootElement = ({ element }) => {
  
  return (    
    <PrismicPreviewProvider repositoryConfigs={repositoryConfigs} config={{mergePreviewData: true}}>
      {element}
    </PrismicPreviewProvider>
  )
  
}