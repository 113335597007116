import React from 'react';
import { css } from '@emotion/react';
import AnimatedBlock, { animTypes, animClass } from '../AnimatedBlock';
import ContentWrap from '../ContentWrap';
import BasicCTA from '../ui/BasicCTA';
import classNames from 'classnames';
import SectionTitle from '../ui/SectionTitle';
import { breakpoints, colors, p2r } from '../../styles';

const style = css`
  margin-bottom: 120px;
  .double-col-copy--wrap {
    display: flex;
    flex-direction: row;

    .content-left {
      width: 470px;
      padding-right: 64px;
    }

    .content-right {
      padding-left: 64px;
      flex: 1;
      border-left: 1px solid rgba(0,0,0,0.2);
    }

    .content-left--top {
      height: 300px;
      margin-bottom: 35px;
    }

    .content--image {
      display: block;
      position: relative;
      text-align: center;
      margin-bottom: 35px;
      height: 300px;
      max-height: 100%;
      > img {
        display: block;
        max-width: 100%;
        max-height: 100%;
        height: 100%;
        width: 100%;
        margin: 0 auto;
        object-fit: contain;
      }
      &.full_bleed_image {
        overflow: hidden;
        > img {
          object-fit: cover;
        }
      }
    }

    h2 {
      max-width: 400px;
    }

    .intro {
      max-width: 380px;
      p {
        font-size: ${p2r(20)};
        line-height: 1.45;
        a {
          color: ${colors.wtRed};
        }
      }
    }
  }

  @media(max-width: ${breakpoints.tablet}px) {
    .double-col-copy--wrap {
      flex-direction: column;

      .content-left {
        flex: 1;
        width: 100%;
        padding-right: 0;
      }
      
      .content-left--top {
        height: auto;
      }

      .content-right {
        padding-left: 0;
        border-left: none;
      }

      .content--image {
        text-align: center;
        margin-bottom: 35px;
        height: auto;
      }

      h2 {
        margin-bottom: 0;
        max-width: 314px;
      }

      .intro {
        max-width: 100%;
        margin-bottom: 30px;
      }
    }
  }

  @media(max-width: ${breakpoints.mobile}px) {
    margin-bottom: 64px;
  }
`;

const DoubleColumnCopyBlock = ({ content, setModalContent }) => {
  const { cta_label, cta_link, image, intro, paragraphs, section_title, theme, title, full_bleed_image } = content;

  return (
    <AnimatedBlock css={style} className={classNames(theme)}>
      <ContentWrap>
        <div className='double-col-copy--wrap'>
          <div className='content-left'>
            <div className='content-left--top'>
              { section_title && (
                <SectionTitle title={section_title} animDelay={0} />
              )}
              { title && (
                <h2 className={classNames(animClass)} data-anim-type={animTypes.SLIDE_UP} data-anim-delay={0.2} dangerouslySetInnerHTML={{__html:title.text}} />
              )}
            </div>
            { intro && (
              <div className={classNames('intro', animClass)} data-anim-type={animTypes.SLIDE_UP} data-anim-delay={0.4} dangerouslySetInnerHTML={{__html:intro.html}}/>
            )}
          </div>
          <div className='content-right'>
            { image && (
              <div className={classNames('content--image', animClass, {'full_bleed_image': full_bleed_image})} data-anim-type={animTypes.FADE} data-anim-delay={0.6}>
                <img src={image.url} alt={image.alt} />
              </div>
            )}
            { paragraphs && (
              <div className={classNames(animClass)} data-anim-type={animTypes.SLIDE_UP} data-anim-delay={0.8} dangerouslySetInnerHTML={{__html:paragraphs.html}}/>
            )}
            { cta_link && cta_label && (
              <BasicCTA 
                className={classNames(animClass)}
                data-anim-type={animTypes.SLIDE_LEFT}
                data-anim-delay={1}
                label={cta_label} 
                link={cta_link} 
                setModalContent={setModalContent}
              />
            )}
          </div>
        </div>
      </ContentWrap>
    </AnimatedBlock>
  )
}

export default DoubleColumnCopyBlock;
