import React, { useEffect, memo } from 'react';
import { useInView } from 'react-intersection-observer';
import { css } from '@emotion/react';
import classNames from 'classnames';

import { useAnimInOut, animTypes } from '../hooks/use-anim-in-out';

export const animClass = 'anim';
export { animTypes };

const style = (st) => css`
  ${st}
  .anim {
    opacity: 0;
  }
`;


function AnimatedBlock({ children, className, css, setIsInView }) {
  const [ref, inView, entry] = useInView({
    rootMargin: '-120px 0px',
    triggerOnce: true
  });

  useAnimInOut(entry?.target, inView, animClass);

  useEffect(() => {
    if (setIsInView) {
      setIsInView(inView);
    }
  }, [setIsInView, inView]);

  return (
    <div css={style(css)} className={classNames(animClass, className)} ref={ref}>
      {children}
    </div>
  );
}

export default memo(AnimatedBlock);
