import React from 'react';
import { css } from '@emotion/react';
import { colors, p2r, breakpoints } from '../../styles';
import BasicCTA from './BasicCTA';

const style = css`
  display: flex;
  flex-direction: row;
  margin-bottom: 80px;
  
  .award-item--image {
    display: block;
    position: relative;
    width: 300px;
    overflow: hidden;
    margin-right: 64px;
    &:before {
      content: '';
      display: block;
      position: relative;
      padding-top: 100%;
      width: 100%;
    }
    > img {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      min-width: 100%;
      min-height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  .award-item--content {
    display: block;
    position: relative;
    flex: 1;
    padding-left: 64px;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      border-left: 1px solid ${colors.wtBlack};
      opacity: 0.2;
    }
    h2 {
      margin-bottom: 40px;
    }
    p {
      font-size: ${p2r(20)};
      line-height: 1.45;
      max-width: 620px;
    }
    .cta {
      margin-top: 20px;
    }
  }

  @media(max-width: ${breakpoints.tablet}px) {
    flex-direction: column;
    margin-bottom: 40px;
    
    .award-item--image {
      max-width: 300px;
      max-height: 300px;
      width: 100%;
      margin-right: 0;
      margin-bottom: 24px;
    }

    .award-item--content {
      padding-left: 0;
      &:before {
        display: none;
      }
      h2 {
        margin-bottom: 24px;
      }
      p {
        font-size: ${p2r(18)};
      }
      .cta {
        margin-top: 12px;
      }
    }
  }
`

const AwardHistoryItem = (props) => {
  const { 
    animDelay,
    title,
    description,
    image,
    link_award_label,
    link_event_label,
    link_award,
    link_event
  } = props;

  const linkDataAward = { link_type: 'Document', uid: link_award.uid };
  const linkDataEvent = { link_type: 'Document', uid: link_event.uid };

  return (
    <div css={style}>
      <div className='award-item--image'>
        <img src={image.url} alt={image.alt} />
      </div>
      <div className='award-item--content'>
        <h2>{title.text}</h2>
        <p>{description.text}</p>
        <BasicCTA className='cta' label={link_award_label} link={linkDataAward} />
        <BasicCTA className='cta' label={link_event_label} link={linkDataEvent} />
      </div>
    </div>
  )
}

export default AwardHistoryItem;
