import React from 'react';
import { animClass, animTypes } from '../AnimatedBlock';
import classNames from 'classnames';

const IconQuote = ({animDelay = 0, ...rest}) => (
  <div className='icon-quote'>
    <svg className={classNames(animClass)} data-anim-type={animTypes.SLIDE_UP_FULL} data-anim-delay={animDelay} xmlns="http://www.w3.org/2000/svg" width="32" height="28.148" viewBox="0 0 32 28.148" {...rest}>
      <path d="M8.4,67.748H20.993V55.9H15.215c0-6.37,2.222-9.333,5.63-10.667V39.6C13.882,40.637,8.4,45.23,8.4,55.9Zm19.408,0H40.4V55.9H34.623c0-6.37,2.222-9.333,5.63-10.667V39.6c-6.963,1.037-12.445,5.63-12.445,16.3Z" transform="translate(-8.4 -39.6)" fill="#de0c23"/>
    </svg>
  </div>
)

export default IconQuote;
