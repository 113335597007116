import { useEffect, useCallback, useState } from 'react';
import gsap from 'gsap';

const inSlide = (el, t = 1, toUp = true, mag = 20) => {
  return gsap.fromTo(el, { y: toUp ? mag : `-${mag}`, opacity: 0 }, { duration: t, y: 0, opacity: 1, ease: 'strong.out', clearProps: 'y, opacity'});
}

const inSlideSide = (el, t = 1, toLeft = true, mag = 20) => {
  return gsap.fromTo(el, { x: toLeft ? mag : `-${mag}`, opacity: 0 }, { duration: t, x: 0, opacity: 1, ease: 'strong.out', clearProps: 'x, opacity'});
}

const inFade = (el, t = 1) => {
  return gsap.fromTo(el, {opacity: 0}, { duration: t, opacity: 1, ease: 'strong.out'});
}

const inHeight = (el, t = 1) => {
  return gsap.fromTo(el, { height: '0%', opacity: 0 }, { duration: t, height: '100%', opacity: 1, ease: 'strong.out', clearProps: 'height, opacity'});
}

const outFade = (el, t = 0.3) => {
  return gsap.to(el, { duration: t, opacity:0, ease: 'strong.out' });
}

export const useAnimInOut = (elem, inView, cls = 'anim') => {
  const [isInView, setIsInView] = useState(false);

  const animIn = useCallback(() => {
    const els = elem.getElementsByClassName(cls);
    gsap.killTweensOf(els);
    let tline = gsap.timeline();
    for (let i=0; i < els.length; i++) {
      const el = els[i];
      const animType = el.dataset.animType;
      const animDelay = el.dataset.animDelay || 0;
      
      switch (animType) {
        case animTypes.SLIDE_UP :
          tline.add( inSlide(el, 1.6, true), animDelay);
          break;
        case animTypes.SLIDE_DOWN :
          tline.add( inSlide(el, 1.6, false), animDelay);
          break;
        case animTypes.SLIDE_LEFT :
          tline.add( inSlideSide(el, 1.6, true), animDelay);
          break;
        case animTypes.SLIDE_RIGHT :
          tline.add( inSlideSide(el, 1.6, false), animDelay);
          break;
        case animTypes.SLIDE_DOWN_FULL :
          tline.add( inSlide(el, 1.6, false, '100%'), animDelay);
          break;
        case animTypes.SLIDE_UP_FULL :
          tline.add( inSlide(el, 1.6, true, '100%'), animDelay);
          break;
        case animTypes.SLIDE_LEFT_FULL :
          tline.add( inSlideSide(el, 1.6, true, '100%'), animDelay);
          break;
        case animTypes.SLIDE_RIGHT_FULL :
          tline.add( inSlideSide(el, 1.6, false, '100%'), animDelay);
          break;
        case animTypes.SCALE_HEIGHT :
          tline.add( inHeight(el, 1.6), animDelay);
          break;
        default :
          tline.add( inFade(el, 1.6), animDelay);
          break;
      }
    }
    return tline;
  }, [elem, cls]);

  const animOut = useCallback(() => {
    const els = elem.getElementsByClassName(cls);
    gsap.killTweensOf(els);
    let tline = gsap.timeline();
    for (let i=0; i < els.length; i++) {
      const el = els[i];
      const animType = el.dataset.animType;
      
      switch (animType) {
        default :
          tline.add( outFade(el, 0.2), 0);
          break;
      }
    }
  }, [elem, cls]);

  useEffect(() => {
    if (elem) {
      if (inView && !isInView) {
        animIn();
        setIsInView(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView, elem, animIn, animOut]);
}

export const animTypes = {
  SLIDE_UP: 'slide_up',
  SLIDE_DOWN: 'slide_down',
  SLIDE_LEFT: 'slide_left',
  SLIDE_RIGHT: 'slide_right',
  SLIDE_LEFT_FULL: 'slide_left_full',
  SLIDE_RIGHT_FULL: 'slide_right_full',
  SLIDE_DOWN_FULL: 'slide_down_full',
  SLIDE_UP_FULL: 'slide_up_full',
  SCALE_HEIGHT: 'scale_height',
  FADE: 'fade',
}
