import React, { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import AnimatedBlock from '../AnimatedBlock';
import ContentWrap from '../ContentWrap';
import SectionTitle from '../ui/SectionTitle';
import AwardHistoryItem from '../ui/AwardHistoryItem';
import { colors, fonts, p2r, breakpoints } from '../../styles';
// import classNames from 'classnames';

const style = css`
  margin-bottom: 120px;

  .list--wrap {
    margin-top: 100px;
  }

  .filter-label {
    ${fonts.HB};
    font-size: ${p2r(16)};
    margin-right: 12px;
  }

  .filter-select {
    display: inline-block;
    position: relative;
    ${fonts.HB};
    
    select {
      cursor: pointer;
      background-color: ${colors.bgGrey};
      border: 1px solid ${colors.wtBlack};
      ${fonts.HR};
      font-size: ${p2r(16)};
      min-width: 190px;
      padding: 10px 15px;
      margin: 0;      
      box-sizing: border-box;
      -webkit-appearance: none;
      -moz-appearance: none;
      background-repeat: no-repeat;
      background-image: url('data:image/svg+xml,<svg id="a" xmlns="http://www.w3.org/2000/svg" width="13" height="9" viewBox="0 0 13.13 9"><path id="b" d="m6.56,7.16L11.4,0l1.73.86-5.5,8.14h-.02s0,0,0,0h-2.13v-.03L0,.86l1.73-.86,4.83,7.16Z" fill="%23de0c23"/></svg>');
      background-position: calc(100% - 15px) center;
      &:focus {
        outline: 0;
        box-shadow: 0px 0px 2px ${colors.wtBlack};
      }
    }
  }
  
  @media(max-width: ${breakpoints.mobile}px) {
    margin-bottom: 64px;
  }
`;

const AwardHistoryBlock = ({ content, items }) => {
  const { section_title } = content;
  const [ filterYear, setFilterYear ] = useState('all');
  const [ filterList, setFilterList ] = useState([]);
  const [ activeList, setActiveList ] = useState([]);

  useEffect(() => {
    const fList = Array.from( items, dat => dat.award_year);
    setFilterList(fList);
  }, [items, setFilterList]);
  
  useEffect(() => {
    const filteredList = (filterYear === 'all') ? items : items.filter(dat =>  dat.award_year === filterYear);
    setActiveList(filteredList);
  }, [setActiveList, filterYear, items]);

  return (
    <AnimatedBlock css={style}>
      <ContentWrap>
        <div className='filter--wrap'>
          { section_title && (
            <SectionTitle title={section_title} animDelay={0} />
          )}
          <div className='filter'>
            <span className='filter-label'>Filter by year</span>
            <div className='filter-select'>
              <select onChange={e => setFilterYear(e.target.value)}>
                <option value='all'>All</option>
                {filterList.map((d,i) => {
                  return (
                    <option value={d} key={i}>{d}</option>    
                  )
                })}
              </select>
            </div>
          </div>
        </div>
        <div className='list--wrap'>
          { activeList.map((d,i) => {
            const animDelay = i * 0.1;
            return (
              <AwardHistoryItem key={i} {...d} animDelay={animDelay} />
            )
          })}
        </div>
      </ContentWrap>
    </AnimatedBlock>
  )
}

export default AwardHistoryBlock;
