import React from 'react';
import Lottie from 'lottie-react';
import { css } from '@emotion/react';
import AnchorScrollButton from '../ui/AnchorScrollButton';
import ContentWrap from '../ContentWrap';
import AnimatedBlock, { animClass, animTypes } from '../AnimatedBlock';
import { fonts, p2r, breakpoints } from '../../styles';
import classNames from 'classnames';
import logoAnim from '../../images/logo.json';

const style = css`
  display: flex;
  position: relative;
  padding: 50px 0;
  min-height: 480px;
  max-height: 900px;
  height: 100vh;
  height: 100dvh;
  align-items: center;

  .hero--wrap {
    position: relative;
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  .hero--logo {
    display: block;
    width: 180px;
    svg {
      display: block;
      width: 100%;
      max-width: 100%;
      max-height: 100%;
      transform: unset !important;
    }
  }

  .hero--content {
    max-width: 440px;
    flex: 1;
    padding-top: 50px;
    margin-left: 50px;
    .desc {
      ${fonts.HR};
      font-size: ${p2r(20)};
    }
  }

  .btn-next {
    margin-top: 46px;
  }

  &.no-logo {
    height: auto;
    .hero--content {
      padding-top: 0;
      margin-left: 0;
      max-width: unset;
    }
  }

  &.no-margin-bottom {
    padding: 195px 0 40px;
    min-height: unset;
    max-height: unset;
    height: auto;
    align-items: flex-start;
  }

  @media(max-width: ${breakpoints.tablet}px) {
    min-height: 280px;
    padding: 100px 0 50px;

    .hero--wrap {
      flex-direction: column;
    }

    .hero--logo {
      display: block;
      width: 83px;
      height: 124px;
    }

    .hero--content {
      padding-top: 0;
      margin-left: 0;
      .desc {
        font-size: ${p2r(18)};
      }
    }

    .btn-next {
      margin-top: 36px;
    }
  }
`;

const ArticleHeroBlock = ({ content }) => {
  const { block_hero_title, hero_desc, scroll_to_anchor, show_logo, theme } = content;
  
  return (
    <AnimatedBlock css={style} className={classNames(theme, {'no-logo': !show_logo})}>
      <ContentWrap>
        <div className='hero--wrap'>
          { show_logo && (
            <div className='hero--logo'>
              <Lottie animationData={logoAnim} loop={false} />
            </div>
          )}
          <div className='hero--content'>
            <div className='stack'>
              <h1 className={classNames(animClass)} data-anim-type={animTypes.SLIDE_LEFT} data-anim-delay={0}>{block_hero_title.text}</h1>
              { hero_desc && (
                <div className={classNames('desc', animClass)} data-anim-type={animTypes.SLIDE_LEFT} data-anim-delay={0.2}>{hero_desc.text}</div>
              )}
              { scroll_to_anchor && (
                <AnchorScrollButton className={classNames('btn-next', animClass)} data-anim-type={animTypes.SLIDE_DOWN} data-anim-delay={1} anchor={scroll_to_anchor} />
              )}
            </div>
          </div>
        </div>
      </ContentWrap>
    </AnimatedBlock>
  )
}

export default ArticleHeroBlock;
