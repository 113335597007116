import React from 'react';
import { css } from '@emotion/react';
import classNames from 'classnames';
import { animClass, animTypes } from '../AnimatedBlock';
import { breakpoints } from '../../styles';

const style = css`
  display: block;
  overflow: hidden;
  margin-bottom: 36px;

  @media(max-width: ${breakpoints.tablet}px) {
    margin-bottom: 30px;
  }
`;

const SectionTitle = (props) => {
  const { className, title, animDelay } = props;

  return (
    <div className={classNames('section-title', className)} css={style}>
      <h4 className={classNames(animClass)} data-anim-type={animTypes.SLIDE_UP_FULL} data-anim-delay={animDelay || 0}>{title}</h4>
    </div>
    
  )
}

export default SectionTitle;
