import React from 'react';
import { css } from '@emotion/react';
import { colors, breakpoints } from '../../styles';

const style = css`
  display: block;
  position: absolute;
  overflow: visible;
  width: 30px;
  height: 24px;
  top: 28px;
  right: 40px;
  
  > span {
    position: absolute;
    display: block;
    width: 100%;
    height: 4px;
    right: 0;
    background-color: ${colors.wtBlack};
    transform-origin: center;
    transition: 0.1s transform ease-out;
    &:after {
      content: '';
      display: block;
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      background-color: ${colors.wtRed};
      transform: scaleX(0);
      transition: 0.2s transform ease-out;
    }
    &:nth-of-type(1) {
      top: 0px;
      transition-delay: 0.1s;
      transform-origin: left;
      &:after {
        transition-delay: 0s;
      }
    }
    &:nth-of-type(2) {
      top: 10px;
      transition-delay: 0.21s;
      &:after {
        transition-delay: 0.05s;
      }
    }
    &:nth-of-type(3) {
      top: 20px;
      transition-delay: 0.22s;
      transform-origin: right;
      &:after {
        transition-delay: 0.1s;
      }
    }
    &:nth-of-type(4) {
      top: 10px;
      transform: rotate(45deg) scaleX(0);
      transition-delay: 0s;
    }
    &:nth-of-type(5) {
      top: 10px;
      transform: rotate(-45deg) scaleX(0);
      transition-delay: 0s;
    }
  }
  
  &[open] {
    > span {
      background-color: ${colors.wtRed};
      &:after {
        background-color: ${colors.white};
      }
      &:nth-of-type(1) {
        transform: scaleX(0);
        transition-delay: 0.1s;
      }
      &:nth-of-type(2) {
        transform: scaleX(0);
        transition-delay: 0.2s;
      }
      &:nth-of-type(3) {
        transform: scaleX(0);
        transition-delay: 0.3s;
      }
      &:nth-of-type(4) {
        transform: rotate(45deg) scaleX(1);
        transition-delay: 0.4s;
      }
      &:nth-of-type(5) {
        transform: rotate(-45deg) scaleX(1);
        transition-delay: 0.4s;
      }
    }
  }

  @media (hover: hover) {
    &:hover {
      > span {
        &:after {
          transform: scaleX(1);
        }
      }
    }
  }

  @media(max-width: ${breakpoints.desktop}px) {
    right: 24px;
  }

  @media(max-width: ${breakpoints.tablet}px) {
    top: 20px;
    right: 18px;
  }

  @media(max-width: ${breakpoints.mobile}px) {
    top: 20px;
    right: 32px;
  }
  
`;

const MenuButton = ({menuOpen, setMenuOpen}) => {

  return (
    <button 
      className='menu-button' 
      css={style} 
      open={menuOpen} 
      onClick={() => setMenuOpen(!menuOpen)} 
      aria-label={menuOpen ? 'close menu' : 'open menu'}
    >
      <span/>
      <span/>        
      <span/>
      <span/>
      <span/>
    </button>
  )
}

export default MenuButton;