import React, { useState, useEffect } from 'react';
import { css } from '@emotion/react';
import MenuButton from './MenuButton';
import MainMenu from './MainMenu';
import NavLogo from '../ui/NavLogo';
import gsap from 'gsap';
import classNames from 'classnames';
import { breakpoints, colors } from '../../styles';

const style = css`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  overflow: visible;
  z-index: 50;
  
  .nav-back {
    display: none;
  }

  &.logo-hidden {
    .nav-logo {
      opacity: 0;
    }
  }
 
  @media(max-width: ${breakpoints.tablet}px) {
    .nav-back {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 0;
      background-color: ${colors.bgGrey};
      opacity: 0.98;
      transition: 0.2s height ease-out;
      &[open] {
        height: 72px;
      }
    }
  }

`;

const TopNav = (props) => {
  const { hideNavLogo, navData, scrollPos, windowHeight } = props;
  const [ menuOpen, setMenuOpen ] = useState(false);
  const [ showLogo, setShowLogo ] = useState(false);
  const [ showFull, setShowFull ] = useState(false);
  const [ triggerY, setTriggerY ] = useState(420);
  
  useEffect(() => {
      const wHeight = Math.min(windowHeight, triggerY);
      const showLogo = (scrollPos > wHeight) || menuOpen;
      setShowLogo(showLogo);
  }, [scrollPos, windowHeight, menuOpen, setShowLogo, triggerY]);

  useEffect(() => {
    if (hideNavLogo) {
      gsap.to('.nav-logo', 0.6, { alpha: showLogo ? 1 : 0, y: showLogo ? 0 : '-60', ease: 'strong.out' });
    } else {
      setShowFull(!showLogo);
    } 
  }, [showLogo, hideNavLogo, setShowFull]);

  useEffect(() => {
    setTriggerY(hideNavLogo ? 420: 30);
  }, [setTriggerY, hideNavLogo]);

  return (
    <div css={style} className={classNames({'logo-hidden': hideNavLogo})}>
      <div className='nav-back' open={showLogo} />
      { navData && (
        <>
          <MainMenu menuOpen={menuOpen} setMenuOpen={setMenuOpen} navData={navData} />
          <MenuButton menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
          <NavLogo className='nav-logo' showWords={showFull} />
        </>
      )}
      
    </div>
  )
}

export default TopNav;
