import { css } from '@emotion/react';
import { breakpoints, fonts, p2r, colors } from '.';

export default css`
  background-color: ${colors.bgGrey};
  color: ${colors.wtBlack};
  ${fonts.HR};
  font-size: ${p2r(20)};
  line-height: 1.45;

  h1 {
    ${fonts.HR};
    font-size: ${p2r(80)};
    letter-spacing: ${p2r(-1.6)};
    margin: 0 0 16px;
    line-height: ${p2r(77)};
  }

  h2 {
    ${fonts.HR};
    font-size: ${p2r(50)};
    letter-spacing: ${p2r(-1.12)};
    margin: 0;
    line-height: ${p2r(63)};
  }

  h3 {
    ${fonts.HB};
    font-size: ${p2r(20)};
    letter-spacing: 0;
    margin: 0 0 ${p2r(36)};
  }

  h4 {
    ${fonts.HR};
    font-size: ${p2r(20)};
    letter-spacing: 0;
    margin: 0;
    color: ${colors.wtRed};
  }

  h5 {
    ${fonts.HB};
    font-size: ${p2r(16)};
    letter-spacing: 0;
    margin: 0 0 ${p2r(16)};
  }

  p {
    ${fonts.HR};
    font-size: ${p2r(14)};
    margin: 0 0 1rem;
    letter-spacing: ${p2r(0)};
    line-height: ${p2r(23)}
  }

  a {
    color: ${colors.wtBlack};
  }

  ul {
    li {
      ${fonts.HR};
      font-size: ${p2r(14)};
      margin: 0 0 1rem;
    }
  }

  footer {
    margin-top: 160px;
  }

  @media(max-width: ${breakpoints.tablet}px) {
    font-size: ${p2r(18)};
    
    h1 {
      font-size: ${p2r(56)};
      line-height: ${p2r(56)};
    }

    h2 {
      ${fonts.HR};
      font-size: ${p2r(36)};
      line-height: ${p2r(42)};
    }

    h3 {
      font-size: ${p2r(18)};
      margin: 0 0 ${p2r(36)};
    }

    h4 {
      font-size: ${p2r(18)};
    }

    h5 {
      ${fonts.HB};
      font-size: ${p2r(16)};
      letter-spacing: 0;
      margin: 0 0 ${p2r(16)};
    }

  }

  @media(max-width: ${breakpoints.mobile}px) {
    
  }
`;
