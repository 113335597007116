import React from 'react';
import { css } from '@emotion/react';
import { colors, p2r } from '../../styles';
import classNames from 'classnames';

const style = css`
  display: flex;
  flex-direction: row;
  
  .social-link {
    display: block;
    position: relative;
    flex: 0 1 auto;
    width: 26px;
    height: 26px;
    margin-right: 10px;
    > svg {
      display: block;
      position: relative;
      width: 100%;
      height: 100%;
      path {
        fill: ${colors.wtBlack};
        transition: 0.2s fill ease-out;
      }
    }
    
    .social-link--highlight {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      width: calc(100% + 8px);
      height: calc(100% + 8px);
      border-radius: 50%;
      background-color: ${colors.white};
      transform: translate(-50%, -50%) scale(0);
      transition: 0.1s transform ease-out;
    }
    
    &.inverted {
      > svg {
        path {
          fill: ${colors.white};
        }
      }
    }
  }

  &.stacked {
    flex-direction: column;
    .social-link {
      margin-right: 0;
      margin-bottom: 20px;
    }
    .social-link--label {
      position: absolute;
      left: calc(100% + 14px);
      top: 2px;
      text-align: left;
      font-size: ${p2r(14)};
      white-space: nowrap;
    }
  }

  @media(hover: hover) {
    .social-link:hover {
      > svg {
        path {
          fill: ${colors.wtRed};
        }
      }
      .social-link--highlight {
        transform: translate(-50%, -50%) scale(1);
      }
    }
  }  
`;

const IconFacebook = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.255 25.101">
    <path d="M25.255,12.627A12.628,12.628,0,1,0,10.655,25.1V16.277H7.448v-3.65h3.206V9.845c0-3.165,1.885-4.913,4.77-4.913a19.419,19.419,0,0,1,2.827.247V8.287H16.659A1.825,1.825,0,0,0,14.6,10.259v2.369h3.5l-.56,3.65H14.6V25.1A12.631,12.631,0,0,0,25.255,12.627" transform="translate(0)" fill="#231f20"/>
  </svg>
)

const IconTwitter = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.102 25.101">
    <path d="M25.1,12.55A12.551,12.551,0,1,1,12.551,0,12.547,12.547,0,0,1,25.1,12.55Zm-14.848,6.62a8.552,8.552,0,0,0,8.61-8.61c0-.132,0-.264-.006-.389A6.2,6.2,0,0,0,20.37,8.6a6.142,6.142,0,0,1-1.738.477A3.024,3.024,0,0,0,19.962,7.4a6.128,6.128,0,0,1-1.92.734,3.028,3.028,0,0,0-5.234,2.071,2.773,2.773,0,0,0,.082.69A8.585,8.585,0,0,1,6.652,7.737a3.024,3.024,0,0,0,.941,4.035A2.973,2.973,0,0,1,6.225,11.4v.038A3.029,3.029,0,0,0,8.654,14.4a3.017,3.017,0,0,1-.8.107,2.889,2.889,0,0,1-.571-.056,3.021,3.021,0,0,0,2.824,2.1,6.083,6.083,0,0,1-3.759,1.293,5.5,5.5,0,0,1-.722-.044,8.431,8.431,0,0,0,4.625,1.368" fill="#231f20"/>
  </svg>
)

const IconInstagram = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.102 25.101">
    <path d="M12.551,25.1A12.553,12.553,0,0,1,7.664.986a12.554,12.554,0,0,1,9.773,23.129A12.479,12.479,0,0,1,12.551,25.1Zm.667-18.994h-.679c-1.769,0-1.975.011-2.657.044a4.728,4.728,0,0,0-1.563.3A3.3,3.3,0,0,0,6.438,8.341a4.762,4.762,0,0,0-.3,1.565c-.03.675-.037.9-.034,2.657s.012,1.977.045,2.657a4.74,4.74,0,0,0,.3,1.563,3.29,3.29,0,0,0,1.888,1.881,4.755,4.755,0,0,0,1.564.3c.592.026.833.035,1.956.035h.7c1.746,0,1.974-.011,2.657-.044a4.753,4.753,0,0,0,1.562-.3,3.293,3.293,0,0,0,1.881-1.888,4.742,4.742,0,0,0,.3-1.564c.03-.688.037-.916.034-2.657s-.011-1.974-.044-2.656a4.744,4.744,0,0,0-.3-1.563,3.173,3.173,0,0,0-.746-1.14,3.135,3.135,0,0,0-1.143-.741,4.737,4.737,0,0,0-1.564-.3C14.589,6.115,14.343,6.106,13.218,6.106Zm-1.41,11.727c-1.031,0-1.265-.008-1.851-.034a3.585,3.585,0,0,1-1.2-.219,2.02,2.02,0,0,1-.743-.481,1.987,1.987,0,0,1-.483-.74,3.577,3.577,0,0,1-.224-1.2c-.032-.669-.039-.871-.043-2.6,0-1.713,0-1.918.033-2.6a3.551,3.551,0,0,1,.219-1.2A2.134,2.134,0,0,1,8.741,7.536a3.555,3.555,0,0,1,1.2-.224c.668-.032.87-.039,2.6-.043h.714c1.08,0,1.314.008,1.89.033a3.528,3.528,0,0,1,1.2.219,2.133,2.133,0,0,1,1.226,1.221,3.537,3.537,0,0,1,.224,1.2c.033.68.04.884.043,2.6s0,1.918-.033,2.6a3.6,3.6,0,0,1-.219,1.2,2.134,2.134,0,0,1-1.221,1.225,3.554,3.554,0,0,1-1.2.224c-.684.032-.889.039-2.6.042Zm.743-8.592h-.006a3.308,3.308,0,0,0,.006,6.617h.006a3.308,3.308,0,0,0-.006-6.617Zm3.431-.91h0a.773.773,0,1,0,.775.772.767.767,0,0,0-.775-.772ZM12.555,14.7h0a2.148,2.148,0,0,1,0-4.3h0a2.148,2.148,0,0,1,0,4.3Z" fill="#231f20"/>
  </svg>
)

const IconNewsletter = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.05 25.1">
    <path d="m25.05,12.55c0-3.47-1.22-6.42-3.65-8.85C18.93,1.23,15.97,0,12.5,0S6.12,1.23,3.65,3.7C1.22,6.13,0,9.08,0,12.55s1.22,6.43,3.65,8.9c2.43,2.43,5.38,3.65,8.85,3.65s6.43-1.22,8.9-3.65c2.43-2.47,3.65-5.43,3.65-8.9m-7-5.15c.63,0,.95.32.95.95v8.45c0,.63-.32.95-.95.95H7c-.27,0-.48-.08-.65-.25-.2-.2-.3-.43-.3-.7v-8.45c0-.27.1-.5.3-.7.17-.17.38-.25.65-.25h11.05m-5.5,5.45l-5.55-3.6v7.55h11.05v-7.55l-5.5,3.6m0-.95l5.45-3.55H7.1l5.45,3.55Z" fill="#231f20"/>
  </svg>
)


const CompLink = ({children, ...rest}) => {
  return (
    <a {...rest}>
      {children}
    </a>
  )
}

const CompButton = ({children, ...rest}) => {
  return (
    <button {...rest}>
      {children}
    </button>
  )
}

const SocialLinks = (props) => {
  const { openNewsletter, links, inverted = false, noNewsletter = false, stacked = false } = props;

  const list = noNewsletter ? links.filter( dat => dat.social_type !== 'newsletter') : links;

  return (
    <div css={style} className={classNames({'stacked': stacked})}>
      {list.map((d,i) => {
        const { social_type, social_link } = d;
        let Icon;
        let label;
        switch (social_type) {
          case 'facebook':
            Icon = IconFacebook;
            label = 'Facebook';
          break;
          case 'twitter':
            Icon = IconTwitter;
            label = 'Twitter';
          break;
          case 'instagram':
            Icon = IconInstagram;
            label = 'Instagram';
          break;
          case 'newsletter':
            Icon = IconNewsletter;
            label = 'E-Newsletter';
          break;
          default:
          break;
        }

        const Comp = social_type === 'newsletter' ? CompButton : CompLink;
        const params = social_type === 'newsletter' ? {onClick: openNewsletter} : { href: social_link.url, target: social_link.target || '_blank', rel:'noopener noreferrer' };

        return (
          <Comp className={classNames('social-link', {'inverted': inverted})} key={i} aria-label={social_type} {...params}>
            <div className='social-link--highlight'/>
            <Icon />
            { stacked && (
              <div className='social-link--label'>{label}</div>
            )}
          </Comp>
        )
      })}
    </div>
  )
}

export default SocialLinks;