import React from 'react';
import { css } from '@emotion/react';
import classNames from 'classnames';
import { colors, p2r, scrollbars } from '../../styles';
import CloseButton from '../ui/CloseButton';
import { trackEvent } from '../../utils';

const style = css`
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  pointer-events: none;
  .screen {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.1);
    display: none;
    pointer-events: none;
    cursor: default;
  }
  .newsletter--wrap {
    position: absolute;
    pointer-events: auto;
    top: 0;
    right: 0;
    overflow: hidden;
    overflow-y: auto;
    ${scrollbars.v};
    width: 0;
    height: 100%;
    box-shadow: 0px 0px 36px 0px rgba(0, 0, 0, .16);
    transition: 0.3s width ease-in-out;
  }
  .newsletter--tray {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 350px;
    min-height: 100%;
    background-color: ${colors.bgFooter};
  }

  .close-button {
    position: absolute;
    top: 20px;
    right: 32px;
  }
  &.open {
    .screen {
      pointer-events: auto;
      display: block;
    }
    .newsletter--wrap {
      width: 350px;
    }
  }
`;

const formStyle = css`
  #mc_embed_signup_scroll {
    display: block;
    padding: 0;
    margin: 0;
  }
  #mc_embed_signup {
    form {
      margin: 0;
      padding: 20px 32px;
    }
  #mc_embed_signup_scroll {
      display: block;
      padding: 0;
      margin: 0;
    }
    .indicates-required {
      text-align: left;
    }
    h2 {
      display: none;
    }
    .indicates-required {
      margin: 0;
    }
    .helper_text {
      background: transparent;
      padding: 2px 0;
      font-size: ${p2r(11)};
      color: ${colors.wtRed};
    }
    .mc-field-group {
      padding-bottom: 0 !important;
      min-height: unset;
      width: 100%;
      display: block;
      label {
        line-height: 1;
        font-size: ${p2r(14)};
        margin-bottom: 7px;
      }
      input {
        border-radius: 0;
        background-color: ${colors.bgFooter};
      }
    }
    .button {
      font-weight: 700 !important;
      font-size: ${p2r(16)} !important;
      background: none;
      color: inherit;
      border: none;
      margin: 0;
      padding: 0;
      font: inherit;
      cursor: pointer;
      &:hover {
        background: none;
      }
    }
  }
`;

const Frame = () => {
  return (
    <div css={formStyle}>
      <link href="//cdn-images.mailchimp.com/embedcode/classic-071822.css" rel="stylesheet" type="text/css" />
      <div id="mc_embed_signup">
        <form action="https://writerstrust.us2.list-manage.com/subscribe/post?u=4b09e5d139538614317991867&amp;id=bc760d3004&amp;f_id=00ea9de0f0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" noValidate>
          <div id="mc_embed_signup_scroll">
            <h2>Subscribe</h2>
            <div className="indicates-required"><span className="asterisk">*</span> indicates required</div>
            <div className="mc-field-group">
              <label htmlFor="mce-FNAME">First Name <span className="asterisk">*</span></label>
              <input type="text" defaultValue="" name="FNAME" className="required" id="mce-FNAME" />
              <span id="mce-FNAME-HELPERTEXT" className="helper_text" />
            </div>
            <div className="mc-field-group">
              <label htmlFor="mce-LNAME">Last Name <span className="asterisk">*</span></label>
              <input type="text" defaultValue="" name="LNAME" className="required" id="mce-LNAME" />
              <span id="mce-LNAME-HELPERTEXT" className="helper_text" />
            </div>
            <div className="mc-field-group">
              <label htmlFor="mce-EMAIL">Email Address <span className="asterisk">*</span></label>
              <input type="email" defaultValue="" name="EMAIL" className="required email" id="mce-EMAIL" />
              <span id="mce-EMAIL-HELPERTEXT" className="helper_text" />
            </div>
            <div className="mc-field-group">
              <label htmlFor="mce-MMERGE15">Postal Code <span className="asterisk">*</span></label>
              <input type="text" defaultValue="" name="MMERGE15" className="required" id="mce-MMERGE15" />
              <span id="mce-MMERGE15-HELPERTEXT" className="helper_text" />
            </div>
            <div id="mce-responses" className="clear">
              <div className="response" id="mce-error-response" style={{display:'none'}}></div>
              <div className="response" id="mce-success-response" style={{display:'none'}}></div>
            </div>
            <div style={{position: 'absolute', left: -5000}} aria-hidden="true">
              <input type="text" name="b_4b09e5d139538614317991867_bc760d3004" tabIndex="-1" defaultValue="" />
            </div>
            <div className="clear">
              <input type="submit" defaultValue="Subscribe" name="subscribe" id="mc-embedded-subscribe" className="button" />
            </div>
          </div>
        </form>
      </div>
      <script type='text/javascript' src='//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js'></script>
      <script type='text/javascript'>{`(function($){window.fnames=new Array(); window.ftypes=new Array();fnames[0]='EMAIL';ftypes[0]='email';fnames[1]='FNAME';ftypes[1]='text';fnames[2]='LNAME';ftypes[2]='text';fnames[3]='ADDRESS';ftypes[3]='address';fnames[4]='PHONE';ftypes[4]='phone';fnames[5]='MMERGE5';ftypes[5]='text';fnames[6]='MMERGE6';ftypes[6]='text';fnames[7]='MMERGE7';ftypes[7]='text';fnames[8]='MMERGE8';ftypes[8]='text';fnames[9]='MMERGE9';ftypes[9]='text';fnames[10]='MMERGE10';ftypes[10]='text';fnames[11]='MMERGE11';ftypes[11]='text';fnames[12]='MMERGE12';ftypes[12]='text';fnames[13]='MMERGE13';ftypes[13]='text';fnames[14]='MMERGE14';ftypes[14]='text';fnames[15]='MMERGE15';ftypes[15]='text';fnames[16]='MMERGE16';ftypes[16]='text';fnames[17]='MMERGE17';ftypes[17]='text';fnames[18]='MMERGE18';ftypes[18]='text';fnames[19]='MMERGE19';ftypes[19]='text';fnames[20]='MMERGE20';ftypes[20]='text';fnames[21]='MMERGE21';ftypes[21]='text';fnames[22]='MMERGE22';ftypes[22]='text';}(jQuery));var $mcj=jQuery.noConflict(true);`}</script>
    </div>
  );
}

const NewsletterSignup = (props) => {
  const { newsletterOpen, setNewsletterOpen } = props;

  const closeTray = () => {
    setNewsletterOpen(false);
    trackEvent('footer_newsletter_tray', 'close');
  }

  return (
    <div css={style} className={classNames({'open': newsletterOpen})}>
      <button className='screen' onClick={closeTray} aria-label='Close' aria-hidden/>
      <div className='newsletter--wrap'>
        <div className='newsletter--tray'>
          <Frame />
          <CloseButton onClick={closeTray} className='close-button' />
        </div>
      </div>
    </div>
  )
}

export default NewsletterSignup;
