import React, { useState } from 'react';
import { css } from '@emotion/react';
import { colors, fonts, p2r, breakpoints } from '../../styles';
import { trackEvent } from '../../utils';
import ContentWrap from '../ContentWrap';
import SocialLinks from '../ui/SocialLinks';
import LockupWTOC from '../ui/LockupWTOC';
import NewsletterSignup from './NewsletterSignup';
import { Link } from 'gatsby';

const style = css`
  display: block;
  position: relative;
  background: ${colors.bgFooter};
  color: ${colors.wtBlack};
  
  .footer--wrap {
    position: relative;
    display: flex;
    flex-direction: row;
    padding: 70px 0 274px;
    > div {
      flex: 1;
    }
  }

  .footer--logos {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-right: 16px;
    > div {
      margin-bottom: 34px;
    }
    .logo-wia {
      margin-top: -10px;
    }
    .logo-wtoc {
      
    }
  }

  .footer-links {
    display: flex;
    flex-direction: column;
    .footer-links--link {
      display: block;
      margin-bottom: 14px;
      font-size: ${p2r(14)};
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  .footer--contact {
    padding: 0 25px;
    > div {
      ${fonts.HR};
      font-size: ${p2r(15)};
      display: block;
      position: relative;
      line-height: ${p2r(23)};
    }
  }

  .footer--social {
    padding: 0 0 0 25px;
  }

  @media(max-width: ${breakpoints.desktop}px) {
    .footer--logos {
      flex-direction: column;
      > div {
        margin-bottom: 34px;
      }
      .logo-wia {
        margin-top: 0;
      }
    }
  }

  @media(max-width: ${breakpoints.tablet}px) {
    .footer--wrap {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 24px 10px;
    }

    .footer--contact {
      padding: 0;
      margin-bottom: 40px;
      > div {
        font-size: ${p2r(15)};
        display: block;
        position: relative;
        line-height: ${p2r(23)};
      }
    }

    .footer--social {
      padding: 0;
    }
  }

  @media(max-width: ${breakpoints.mobile}px) {
    .footer-links {
      .footer-links--link {
        font-size: ${p2r(12)};
      }
    }

    .footer--logos {
      .logo-wtoc,
      .logo-wia {
        width: 130px;
        > img {
          width: 100%;
        }
      }
    }
    
    .footer--wrap {
      
    }

    h5 {
      font-size: ${p2r(14)};
    }

    .footer--contact {
      > div {
        font-size: ${p2r(12)};
      }
    }
  }
`;

const Footer = (props) => {
  const {
    top_nav_list,
    contact_email,
    contact_name,
    contact_title,
    label_contact,
    label_social,
    link_wtoc_label,
    logo_wtoc,
    logo_wia,
    link_wtoc,
    social_list,
  } = props;

  const [newsletterOpen, setNewsletterOpen] = useState(false);

  const openNewsletter = () => {
    trackEvent('footer_newsletter_tray', 'open');
    setNewsletterOpen(true);
  }

  return (
    <div css={style}>
      <ContentWrap>
        <div className='footer--wrap'>
          <div className='footer--logos'>
            <div className='logo-wia'>
              <img src={logo_wia.url} alt={logo_wia.alt} />
            </div>
            <LockupWTOC link_wtoc={link_wtoc} logo_wtoc={logo_wtoc} link_wtoc_label={link_wtoc_label} />
          </div>
          <div className='footer-links'>
            { top_nav_list.map((d,i) => {
              const url= `/${d.link.uid}`;
              return (
                <Link className='footer-links--link' to={url} key={i}>{d.label}</Link>
              )
            })}
          </div>
          <div className='footer--contact'>
            { label_contact && <h5>{label_contact}</h5> }
            { contact_name && <div>{contact_name}</div> }
            { contact_title && <div>{contact_title}</div> }
            { contact_email && <div><a href={`mailto:${contact_email}`}>{contact_email}</a></div> }
          </div>
          <div className='footer--social'>
            { label_social && <h5>{label_social}</h5> }
            <SocialLinks links={social_list} stacked={true} openNewsletter={openNewsletter}/>
          </div>
        </div>
      </ContentWrap>
      <NewsletterSignup newsletterOpen={newsletterOpen} setNewsletterOpen={setNewsletterOpen} />
    </div>
  )
}

export default Footer;
