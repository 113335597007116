import gsap from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin';

gsap.registerPlugin(ScrollToPlugin);

const isBrowser = (typeof window !== 'undefined');

export const scrollToAnchor = (id, offset = 0, callBack = null) => {
  if (id) {
    const targetEl = document.querySelector(`#${id}`);
    if (targetEl && isBrowser) {
      const scTop = window.scrollY || window.pageYOffset;
      const dy = targetEl.getBoundingClientRect().top + scTop;
      gsap.to( window, { scrollTo: { y: dy, offsetY: offset }, duration: 0.8, ease: 'strong.out', onComplete: callBack, overwrite: true });
    } else if (callBack) {
      callBack();
    }
  }
}

export const toTop =
  isBrowser ?
  () => { isBrowser && window.scrollTo(0, 0) } :
  () => {};

export const hasWindow = () => typeof window !== 'undefined';

export const getDocHeight = () => {
  if (typeof document === 'undefined') return;
  return Math.max(
    document.body.scrollHeight, 
    document.body.offsetHeight, 
    document.body.clientHeight,
    document.documentElement.scrollHeight,
    document.documentElement.offsetHeight,
    document.documentElement.clientHeight
  );
}

export const trackEvent = (eventLabel, eventDesc) => {
  if (typeof window !== 'undefined' && window.dataLayer) {
    window.dataLayer.push(
      { event: eventLabel, label: eventDesc }
    );
  }
}
