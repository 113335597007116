import React from 'react';
import { css } from '@emotion/react';
import AnimatedBlock, { animTypes, animClass } from '../AnimatedBlock';
import ContentWrap from '../ContentWrap';
import BasicCTA from '../ui/BasicCTA';
import classNames from 'classnames';
import SectionTitle from '../ui/SectionTitle';
import { fonts, p2r, breakpoints } from '../../styles';

const style = css`
  margin-bottom: 120px;

  .list-copy--wrap {
    display: flex;
    position: relative;
    flex-direction: row;
  }

  .list-copy--image {
    position: relative;
    width: 300px;
    margin-right: 64px;
    img {
      display: block;
      max-height: 100%;
      max-width: 100%;
    }
  }

  .list-copy--content {
    flex: 1;
    display: block;
    padding-left: 64px;
    border-left: 1px solid rgba(0,0,0,0.2);
    h5 {
      ${fonts.HB};
      font-size: ${p2r(18)};
      margin-bottom: 25px;
    }
  }

  .column-wrap {
    display: block;
    position: relative;
    columns: 2;
    column-gap: 50px;
    margin: 0;
    ul {
      margin: 0;
      padding: 0 0 0 16px;
      li {
        break-inside: avoid-column;
      }
    }
  }

  .list-wrap {
    margin-bottom: 14px;
  }
  
  @media(max-width: ${breakpoints.tablet}px) {
    .list-copy--wrap {
      flex-direction: column;
    }
    
    .list-copy--image {
      width: 100%;
      height: auto;
      margin-right: 0;
      margin-bottom: 30px;
    }

    .list-copy--content {
      padding-left: 0;
      border-left: none;
      h5 {
        font-size: ${p2r(16)};
      }
    }

    .column-wrap {
      column-gap: 40px;
    }
  }

  @media(max-width: ${breakpoints.mobile}px) {
    margin-bottom: 64px;
    .column-wrap {
      columns: 1;
    }
  }
`;

const ListCopyBlock = ({ content, setModalContent }) => {
  const { theme, cta_label, cta_link, image, paragraphs, title, section_title } = content;

  return (
    <AnimatedBlock css={style} className={classNames(theme)}>
      <ContentWrap>
        <div className='list-copy--wrap'>
          <div className={classNames('list-copy--image', animClass)} data-anim-type={animTypes.FADE} data-anim-delay={0}>
            <img src={image.url} alt={image.alt} />
          </div>
          <div className='list-copy--content'>
            { section_title && (
              <SectionTitle title={section_title.text} animDelay={0.2} />
            )}
            { title && (
              <h5 className={classNames(animClass)} data-anim-type={animTypes.SLIDE_UP} data-anim-delay={0.3}>{title.text}</h5>
            )}
            <div className='column-wrap'>
              { paragraphs && (
                <div className={classNames('list-wrap', animClass)} data-anim-type={animTypes.SLIDE_UP} data-anim-delay={0.4} dangerouslySetInnerHTML={{__html: paragraphs.html}}/>
              )}
              { cta_link && cta_label && (
                <BasicCTA 
                  className={classNames('list-cta', animClass)}
                  data-anim-type={animTypes.SLIDE_LEFT}
                  data-anim-delay={0.6}
                  label={cta_label} 
                  link={cta_link} 
                  setModalContent={setModalContent}
                />
              )}
            </div>
          </div>
        </div>
      </ContentWrap>
    </AnimatedBlock>
  )
}

export default ListCopyBlock;
