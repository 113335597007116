import React from 'react';
import { css } from '@emotion/react';
import AnimatedBlock, { animClass, animTypes } from '../AnimatedBlock';
import ContentWrap from '../ContentWrap';
import classNames from 'classnames';
import SectionTitle from '../ui/SectionTitle';
import { colors, breakpoints } from '../../styles';
import BasicCTA from '../ui/BasicCTA';

const style = css`
  .explore--wrap {
    display: block;
    position: relative;
    padding-top: 160px;
    &:before{
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      border-top: 1px solid ${colors.wtBlack};
      opacity: 0.2;
    }
  }
  
  .explore--links {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .explore-link {
    display: flex;
    flex-direction: row;
    margin-right: 24px;
    .explore-link--content {
      flex: 1;
      padding-left: 20px;
      p {
        max-width: 290px;
      }
    }
    .explore-link--image {
      display: block;
      position: relative;
      overflow: hidden;
      width: 190px;
      height: 190px;
    }
    .explore-link--thumb {
      width: 100%;
      height: 100%;
      > img {
        width: 100%;
        height: 100%;
      }
    }
  }

  @media(max-width: ${breakpoints.tablet}px) {
    .explore--wrap {
      padding-top: 100px;
    }
    .explore--links {
      grid-template-columns: repeat(1, 1fr);
    }
    .explore-link {
      margin-right: 0;
      margin-bottom: 24px;
      .explore-link--image {
        width: 200px;
        height: 200px;
      }
    }
  }

  @media(max-width: ${breakpoints.mobile}px) {
    .explore-link {
      .explore-link--image {
        width: 64px;
        height: 64px;
      }
    }
  }
`;

const ExploreBlock = ({ content, items }) => {
  const { section_title } = content;

  return (
    <AnimatedBlock css={style}>
      <ContentWrap>
        <div className='explore--wrap'>
          { section_title && (
            <SectionTitle title={section_title} animDelay={0} />
          )}
          <div className='explore--links'>
            { items.map((d,i) => {
              const {
                override_cta,
                override_description,
                override_title,
                override_image,
                linked_page,
              } = d;
              
              const pageData = linked_page?.document?.data;
              const linkDest = linked_page.uid;
              const linkTitle = override_title || pageData.link_title;
              const linkDesc = override_description || pageData.link_description;
              const linkLabel = override_cta || pageData.link_cta;
              const linkData = { link_type: 'Document', uid: linkDest };
              const linkImage = override_image.url ?  override_image : pageData.link_image;
              const animDelay = i * 0.2;

              return (
                <div className='explore-link' key={i}>
                  <div className='explore-link--image'>
                    <div className={classNames('explore-link--thumb', animClass)} data-anim-type={animTypes.SLIDE_RIGHT_FULL} data-anim-delay={animDelay}>
                      <img src={linkImage.url} alt={linkImage.alt} />
                    </div>
                  </div>
                  <div className={classNames('explore-link--content', animClass)} data-anim-type={animTypes.SLIDE_LEFT} data-anim-delay={animDelay + 0.1}>
                    <h5>{linkTitle}</h5>
                    <p>{linkDesc}</p>
                    <BasicCTA label={linkLabel} link={linkData} />
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </ContentWrap>
    </AnimatedBlock>
  )
}

export default ExploreBlock;
