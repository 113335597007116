module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-prismic-previews/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"westoninternationalaward","accessToken":"MC5aR2VLbEJBQUFDQUFwT0Zw.fu-_ve-_vQfvv73vv73vv73vv70R77-977-977-977-9akRU77-977-9fO-_vSXvv73vv73vv71L77-9Ru-_ve-_ve-_vXLvv70","customTypesApiToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoibWFjaGluZTJtYWNoaW5lIiwiZGJpZCI6Indlc3RvbmludGVybmF0aW9uYWxhd2FyZC1kMTg4NDgyZC1iNmNjLTQ1MWEtODY0NC00NThjOWQyMjg0NDlfNCIsImRhdGUiOjE2ODQ1MDc1NzksImRvbWFpbiI6Indlc3RvbmludGVybmF0aW9uYWxhd2FyZCIsImlhdCI6MTY4NDUwNzU3OX0.TlweKMY1LrdkNb7tXsu-688pSLaHduORZhCh-LKBztI","path":"/preview"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"browser","icon":"static/icons/icon-144x144.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"258d8827f156925c9424c41a1f763f02"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-9TNRVKPX27","GTM-T9KTH6V"],"pluginConfig":{"head":true,"respectDNT":false,"exclude":[],"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0},"gtagConfig":{}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
