import React from 'react';
import { css } from '@emotion/react';
import { colors, breakpoints } from '../../styles';
import MainMenuLink from './MainMenuLink';
import ContentWrap from '../ContentWrap';
import SocialLinks from '../ui/SocialLinks';
import LockupWTOC from '../ui/LockupWTOC';

const style = css`
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  overflow: hidden;
  background: ${colors.wtBlack};
  transition: 0.1s height ease-in;

  .main-menu--content {
    height: 100%;
    overflow-y: auto;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .main-menu--wrap {
    padding: 64px 0 0;
    display: block;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .main-menu--list-wrap {
    display: block;
    position: relative;
    flex: 1;
    width: 100%;
  }

  .main-menu--foot {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    height: 120px;
  }

  .main-menu--logos {
    padding: 40px 0 40px;
  }

  .main-menu--social {
    display: block;
    position: relative;
    padding: 0 0 50px;
  }

  &[open] {
    height: 100%;
    transition: 0.3s height ease-out;
  }

  @media(max-width: ${breakpoints.tablet}px) {
    .main-menu--wrap {
      padding: 96px 0 0;
    }
  }
`;

const MainMenu = (props) => {
  const { navData, menuOpen, setMenuOpen } = props;
  const { top_nav_list, social_list, logo_wtoc_white, link_wtoc, link_wtoc_label } = navData;

  return (
    <nav css={style} open={menuOpen}>
      <ContentWrap className='main-menu--content'>
        <div className='main-menu--wrap'>
          <div className='main-menu--list-wrap'>
            {top_nav_list.map((d,i) => {
              return (
                <MainMenuLink {...d} key={i} onClick={() => setMenuOpen(false)} />
              )
            })}
          </div>
          <div className='main-menu--foot'>
            <div className='main-menu--logos'>
              <LockupWTOC link_wtoc={link_wtoc} logo_wtoc={logo_wtoc_white} link_wtoc_label={link_wtoc_label} inverted={true}/>
            </div>
            <div className='main-menu--social'>
              <SocialLinks links={social_list} inverted={true} noNewsletter={true} />
            </div>
          </div>
        </div>
      </ContentWrap>
    </nav>
  )
}

export default MainMenu;
