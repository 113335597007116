import { css } from '@emotion/react'
import colors from './colors';

const scrollbars = {
  v: css`
    ::-webkit-scrollbar {
      width: 5px;
      height: 8px;
    }
    ::-webkit-scrollbar-track {
      background: ${colors.bgFooter}; 
    }
    ::-webkit-scrollbar-thumb {
      background: ${colors.wtRed}; 
    }
  `,
  h: css`
    ::-webkit-scrollbar {
    width: 5px;
    height: 8px;
  }
  ::-webkit-scrollbar-track {
    background: ${colors.bgFooter}; 
  }
  ::-webkit-scrollbar-thumb {
    background: ${colors.scrollbarH}; 
  }
  `,
}

export default scrollbars;
