import React from 'react';
import { css } from '@emotion/react';
import { colors, fonts, p2r, breakpoints } from '../../styles';
import classNames from 'classnames';

const style = css`
  display: block;
  position: relative;
  margin-top: 8px;
  
  a {
    display: block;
    position: relative;
    ${fonts.HR};
    font-size: ${p2r(14)};
    text-decoration: none;
    img {
      height: 45px;
    }
    > span {
      color: ${colors.wtBlack};
      display: block;
      text-decoration: underline;
    }
  }
  
  &.inverted {
    a {
      > span {
        color: ${colors.white};
      }
    }
  }

  @media(max-width: ${breakpoints.tablet}px) {
    a {
      img {
        height: 36px;
      }
    }
  }
`;

const LockupWTOC = (props) => {
  const { link_wtoc, logo_wtoc, link_wtoc_label, inverted = false } = props;

  return (
    <div css={style} className={classNames({'inverted': inverted})}>
      <a href={link_wtoc.url} target={link_wtoc.target}>
        <img src={logo_wtoc.url} alt={logo_wtoc.alt} />
        <span>{link_wtoc_label}</span>
      </a>
    </div>
    
  )
}

export default LockupWTOC;
