import React from 'react';
import { css } from '@emotion/react';
import AnimatedBlock, { animClass, animTypes } from '../AnimatedBlock';
import { colors, p2r } from '../../styles';
import ContentWrap from '../ContentWrap';
import classNames from 'classnames';
import BasicCTA from '../ui/BasicCTA';

const style = css`
  margin-bottom: 120px;
  
  .notice--wrap {

  }

  .notice--box {
    display: flex;
    position: relative;
    flex-direction: row;
    align-items: center;
    padding: 40px 24px;
    overflow: hidden;
  }

  .notice--bg {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${colors.wtBlack};
  }

  .notice--info {
    position: relative;
    flex: 1;
    color: ${colors.white};
    h5 {
      font-size: ${p2r(20)};
      margin-bottom: 8px;
    }
    p {
      font-size: ${p2r(20)};
    }
  }

  .notice--icon {
    position: relative;
    padding-right: 24px;
    img {
      display: block;
      height: 64px;
      width: 64px;
    }
  }
`;

const NoticeBlock = ({ content, setModalContent }) => {
  const { cta_label, narrow_article_width, title, paragraphs, icon, cta_link } = content;

  return (
    <AnimatedBlock css={style}>
      <ContentWrap narrow={narrow_article_width}>
        <div className='notice--wrap'>
          <div className='notice--box'>
            <div className={classNames('notice--bg', animClass)} data-anim-type={animTypes.SLIDE_RIGHT_FULL} data-anim-delay={0}/>
            <div className='notice--info'>
              { title && (
                <h5 className={classNames(animClass)} data-anim-type={animTypes.SLIDE_LEFT} data-anim-delay={0.7}>{title.text}</h5>
              )}
              { paragraphs && (
                <div className={classNames(animClass)} data-anim-type={animTypes.SLIDE_LEFT} data-anim-delay={0.8} dangerouslySetInnerHTML={{__html: paragraphs.html}}/>
              )}
              { cta_link && cta_label && (
                <BasicCTA 
                  className={classNames(animClass, 'white-cta')}
                  data-anim-type={animTypes.SLIDE_LEFT}
                  data-anim-delay={0.9}
                  label={cta_label} 
                  link={cta_link} 
                  setModalContent={setModalContent}
                />
              )}
            </div>
            { icon && (
              <div className={classNames('notice--icon', animClass)} data-anim-type={animTypes.FADE} data-anim-delay={1}>
                <img src={icon.url} alt={icon.alt} />
              </div>
            )}
          </div>
        </div>
      </ContentWrap>
    </AnimatedBlock>
  )
}

export default NoticeBlock;
