import React, { useEffect, useRef } from 'react';
import { css } from '@emotion/react';
import ContentWrap from '../ContentWrap';
import AnimatedBlock, { animClass, animTypes } from '../AnimatedBlock';
import { p2r, colors, breakpoints } from '../../styles';
import classNames from 'classnames';

const style = css`
  display: block;
  position: relative;
  margin-bottom: 120px;

  .embed-wrap {
    display: block;
    position: relative;
    width: 100%;
  }
`;

const formStyle = css`
  
  form {
    margin: 0;
    padding: 20px 0;
  }
  
  .mc_embed_signup_scroll {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px 40px;
    padding: 0;
    margin: 0;
  }

  .indicates-required {
    display: block;
    grid-column: span 2;
    text-align: left;
    font-size: ${p2r(14)};
  }
  
  .indicates-required {
    margin: 0;
  }
  
  .helper_text {
    background: transparent;
    padding: 2px 0;
    font-size: ${p2r(11)};
    color: ${colors.wtRed};
  }

  .responses {
    grid-column: span 2;
  }

  .asterisk {
    color: ${colors.wtRed};
  }
  
  .mc-field-group {
    padding-bottom: 0 !important;
    min-height: unset;
    width: 100%;
    display: block;

    label {
      display: block;
      line-height: 1;
      margin-bottom: 8px;
      font-size: ${p2r(18)};
    }

    input {
      border-radius: 0;
      background-color: transparent;
      border: 1px solid ${colors.scrollbarH};
      font-size: ${p2r(20)};
      padding: 8px;
      width: 100%;
      font-weight: 300;
    }
  }
  
  .submit-btn {
    font-weight: 700;
    background-color: ${colors.wtRed};
    color: ${colors.bgGrey};
    padding: 6px 20px;
    transition: 0.2s background-color ease-out;
  }

  @media (hover:hover) {
    .submit-btn {
      &:hover {
        background-color: ${colors.bannerBlack};
      }
    }
  }

  @media(max-width: ${breakpoints.tablet}px) {
    .mc_embed_signup_scroll {
      grid-gap: 20px;
    }
  }

  @media(max-width: ${breakpoints.mobile}px) {
    .mc_embed_signup_scroll {
      grid-template-columns: 1fr;
    }

    .indicates-required { 
      grid-column: span 1;
    }

    .responses {
      grid-column: span 1;
    }
  }
`;

const SignupFormBlock = ({ content }) => {
  const { form_id } = content;

  const formAction = `https://writerstrust.us2.list-manage.com/subscribe/post?u=4b09e5d139538614317991867&amp;id=bc760d3004&amp;f_id=${form_id || "00ea9de0f0"}`;
  
  return (
    <AnimatedBlock css={style}>
      <ContentWrap>
        <div css={formStyle} className={classNames(animClass)} data-anim-type={animTypes.FADE} data-anim-delay={0.2}>
          <form action={formAction} method="post" id="mc-embedded-subscribe-form-block" name="mc-embedded-subscribe-form-block" className="validate" target="_blank" noValidate>
            <div className="mc_embed_signup_scroll">
              <div className="indicates-required"><span className="asterisk">*</span> indicates required</div>
              <div className="mc-field-group">
                <label htmlFor="mce-FNAME">First Name <span className="asterisk">*</span></label>
                <input type="text" defaultValue="" name="FNAME" className="required" id="mce-FNAME" />
                <span id="mce-FNAME-HELPERTEXT" className="helper_text" />
              </div>
              <div className="mc-field-group">
                <label htmlFor="mce-LNAME">Last Name <span className="asterisk">*</span></label>
                <input type="text" defaultValue="" name="LNAME" className="required" id="mce-LNAME" />
                <span id="mce-LNAME-HELPERTEXT" className="helper_text" />
              </div>
              <div className="mc-field-group">
                <label htmlFor="mce-EMAIL">Email Address <span className="asterisk">*</span></label>
                <input type="email" defaultValue="" name="EMAIL" className="required email" id="mce-EMAIL" />
                <span id="mce-EMAIL-HELPERTEXT" className="helper_text" />
              </div>
              <div className="mc-field-group">
                <label htmlFor="mce-MMERGE15">Postal Code <span className="asterisk">*</span></label>
                <input type="text" defaultValue="" name="MMERGE15" className="required" id="mce-MMERGE15" />
                <span id="mce-MMERGE15-HELPERTEXT" className="helper_text" />
              </div>
              <div id="mce-responses" className={classNames("clear", "responses")}>
                <div className="response" id="mce-error-response" style={{display:'none'}}></div>
                <div className="response" id="mce-success-response" style={{display:'none'}}></div>
              </div>
              <div style={{position: 'absolute', left: -5000}} aria-hidden="true">
                <input type="text" name="b_4b09e5d139538614317991867_bc760d3004" tabIndex="-1" defaultValue="" />
              </div>
              <div className="clear">
                <input type="submit" name="subscribe" value="Subscribe" className={classNames("button", "submit-btn")} />
              </div>
            </div>
          </form>
        </div>
      </ContentWrap>
    </AnimatedBlock>
  )
}

export default SignupFormBlock;
