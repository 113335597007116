import React from 'react';
import { css } from '@emotion/react';
import AnimatedBlock, { animTypes, animClass } from '../AnimatedBlock';
import ContentWrap from '../ContentWrap';
import SectionTitle from '../ui/SectionTitle';
import classNames from 'classnames';
import { breakpoints, p2r } from '../../styles';

const style = css`
  margin-bottom: 120px;
  .map--wrap {

  }

  .map--content {
    display: flex;
    flex-direction: row;
    .content-copy {
      flex: 1;
      padding-right: 32px;
      .intro p {
        font-size: ${p2r(20)};
        line-height: 1.45;
      }
      .paragaphs p,
      .paragaphs ul,
      .paragaphs li {
        font-size: ${p2r(14)};
        line-height: 1.45;
      }
      .paragaphs ul {
        padding-left: 20px;
      }
    }
    
    .content-map {
      flex: 1;
      display: block;
      min-height: 320px;
      iframe {
        width: 100%;
        height: 100%;
      }
    }
  }

  @media(max-width: ${breakpoints.mobile}px) {
    margin-bottom: 64px;

    .map--content {
      flex-direction: column;
      .content-copy {
        padding-right: 0;
      }
      
      .content-map {
        flex: 1;
        iframe {
          width: 100%;
          height: 320px;
        }
      }
    }
  }
`;

const MapBlock = ({ content }) => {
  const { narrow_article_width, section_title, paragraphs, intro, map_iframe_src } = content;

  return (
    <AnimatedBlock css={style}>
      <ContentWrap narrow={narrow_article_width}>
        <div className='map--wrap'>
          { section_title && (
            <SectionTitle title={section_title} animDelay={0} />
          )}
        </div>
        <div className='map--content'>
          <div className='content-copy'>
            { intro && (
              <div className={classNames('intro', animClass)} data-anim-type={animTypes.SLIDE_UP} data-anim-delay={0} dangerouslySetInnerHTML={{__html:intro.html}}/>
            )}
            { paragraphs && (
              <div className={classNames('paragaphs', animClass)} data-anim-type={animTypes.SLIDE_UP} data-anim-delay={0.1} dangerouslySetInnerHTML={{__html:paragraphs.html}}/>
            )}
          </div>
          <div className={classNames('content-map', animClass)} data-anim-type={animTypes.FADE} data-anim-delay={0.2}>
            <iframe src={map_iframe_src} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade" />
          </div>
        </div>
      </ContentWrap>
    </AnimatedBlock>
  )
}

export default MapBlock;
