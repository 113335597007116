import React from 'react';
import { css } from '@emotion/react';
import AnimatedBlock, { animClass, animTypes } from '../AnimatedBlock';
import ContentWrap from '../ContentWrap';
import IconQuote from '../icons/IconQuote';
import classNames from 'classnames';
import { p2r, breakpoints, colors } from '../../styles';
import BasicCTA from '../ui/BasicCTA';
import SectionTitle from '../ui/SectionTitle';

const style = css`
  margin-bottom: 120px;
  .basic-copy--wrap {
    h2 {
      margin-bottom: 24px;
      max-width: 616px;
    }
    &.narrow {
      padding: 0 24px;
    }
  }

  .basic-copy--content {
    display: flex;
    flex-direction: row;
    .content-image {
      width: 300px;
      margin-right: 64px;
      > img {
        display: block;
        width: 100%;   
      }
    }
    .content-copy {
      flex: 1;
      .intro p {
        font-size: ${p2r(20)};
        line-height: 1.45;
      }
      .paragaphs p {
        font-size: ${p2r(14)};
        line-height: 1.45;
      }
    }
    &.has-image {
      .content-copy {
        padding-left: 69px;
        border-left: 1px solid rgba(0,0,0,0.2);
      }
    }
  }

  .icon-quote {
    width: 32px;
    margin-bottom: 26px;
    overflow: hidden;
  }

  .attrib {
    margin-top: 24px;
    font-size: ${p2r(12)};
    font-style: italic;
  }

  &.image-right {
    .basic-copy--content {
      .content-image {
        order: 1;
        margin-right: 0;
        margin-left: 62px;
      }
      .content-copy {
        order: 0;
        padding-left: 0;
        padding-right: 69px;
      }
      &.has-image {
        .content-copy {
          border-left: none;
          border-right: 1px solid rgba(0,0,0,0.2);
        }
      }
    }
  }

  &.image-left-wide {
    .basic-copy--content {
      .content-image {
        flex: 1;
        width: 100%;
      }
    }
  }

  &.add-borders {
    padding: 0 0;
    
    .content-border {
      display: block;
      width: 100%;
      height: 1px;
      background-color: ${colors.wtBlack};
      opacity: 0.2;
    }
    
    .border-top {
      margin-bottom: 60px;
    }

    .border-bottom {
      margin-top: 60px;
    }
  }

  @media(max-width: ${breakpoints.tablet}px) {
    .basic-copy--content {
      .content-image {
        width: 120px;
        margin-right: 20px;
      }
      .content-copy {
        padding-left: 0;
        border-left: none;
        p {
          font-size: ${p2r(18)};
        }
      }
    }

    .icon-quote {
      margin-bottom: 16px;
    }

    .basic-copy--wrap {
      &.narrow {
        padding: 0;
      }
    }

    &.image-right {
      .basic-copy--content {
        .content-image {
          margin-left: 20px;
        }
        .content-copy {
          padding-right: 0;
          border-right: none;
        }
      }
    }

    &.image-left,
    &.image-left-wide {
      .basic-copy--content {
        flex-direction: column;
        .content-image {
          margin: 0 0 24px 0;
          width: 100%;
        }
        .content-copy {
          margin: 0;
          border-left: none;
          padding-left: 0;
        }
      }
    }

  }

  @media(max-width: ${breakpoints.mobile}px) {
    margin-bottom: 64px;
    .basic-copy--content {
      .content-image {
        width: 64px;
      }
    }
  }
`;

const BasicCopyBlock = ({ content, setModalContent }) => {
  const { intro, paragraphs, title, section_title, cta_label, is_quote, theme, cta_link, image, narrow_article_width, show_borders, attrib } = content;

  const hasImage = (theme === 'image-left' || theme === 'image-left-wide' || theme === 'image-right') && image;
  
  return (
    <AnimatedBlock css={style} className={classNames(theme, {'add-borders': show_borders})}>
      <ContentWrap narrow={narrow_article_width}>
        { show_borders && (
          <div className={classNames('content-border', 'border-top')}/>
        )}
        <div className={classNames('basic-copy--wrap', {'narrow':narrow_article_width})}>
          { section_title && (
            <SectionTitle title={section_title} animDelay={0} />
          )}
          <div className={classNames('basic-copy--content', {'has-image': hasImage})}>
            { hasImage && image && (
              <div className={classNames('content-image', animClass)} data-anim-type={animTypes.FADE} data-anim-delay={0.4}>
                <img src={image.url} alt={image.alt} />
              </div>
            )}
            <div className='content-copy'>
              { is_quote && (
                <IconQuote animDelay={0.5} />
              )}
              { title && title.text && (
                <h2 className={classNames(animClass)} data-anim-type={animTypes.SLIDE_UP} data-anim-delay={0.2} dangerouslySetInnerHTML={{__html:title.text}} />
              )}
              { intro && (
                <div className={classNames('intro', animClass)} data-anim-type={animTypes.SLIDE_UP} data-anim-delay={0.6} dangerouslySetInnerHTML={{__html:intro.html}}/>
              )}
              { paragraphs && (
                <div className={classNames('paragaphs', animClass)} data-anim-type={animTypes.SLIDE_UP} data-anim-delay={0.6} dangerouslySetInnerHTML={{__html:paragraphs.html}}/>
              )}
              { attrib && (
                <div className={classNames('attrib', animClass)} data-anim-type={animTypes.SLIDE_UP} data-anim-delay={0.7} dangerouslySetInnerHTML={{__html:attrib}}/>
              )}
              { cta_link && cta_label && (
                <BasicCTA 
                  className={classNames(animClass)}
                  data-anim-type={animTypes.SLIDE_LEFT}
                  data-anim-delay={1}
                  label={cta_label} 
                  link={cta_link} 
                  setModalContent={setModalContent}
                />
              )}
            </div>
          </div>
        </div>
        { show_borders && (
          <div className={classNames('content-border', 'border-bottom')}/>
        )}
      </ContentWrap>
    </AnimatedBlock>
  )
}

export default BasicCopyBlock;
