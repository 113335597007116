const colors = {
  black: '#000000',
  white: '#FFFFFF',


  bgGrey: '#F3F3F3',
  bannerBlack: '#171717',
  bgFooter: '#DCDEE0',

  scrollbarH: '#A7A7A7',
  
  wtBlack: '#231F20',
  wtRed: '#DE0C23',
}

export default colors
