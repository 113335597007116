import React from 'react';
import { css } from '@emotion/react';
import AnimatedBlock from '../AnimatedBlock';
import SectionTitle from '../ui/SectionTitle';
import ContentWrap from '../ContentWrap';
import { scrollbars, breakpoints } from '../../styles';
import { TwitterTweetEmbed } from 'react-twitter-embed';

const style = css`
  margin-bottom: 120px;
  
  .twitter--list-wrap {
    display: block;
    position: relative;
    width: 100%;
    min-height: 200px;
    overflow: hidden;
    overflow-x: auto;
    ${scrollbars.h};
  }

  .twitter--scroll {
    display: flex;
    flex-direction: row;
    padding-bottom: 40px;
  }

  .tweet {
    display: block;
    position: relative;
    padding-right: 20px;
    flex: 1 0 33.333333%;
  }

  .twitter-tweet {
    width: 100%;
    position: relative;
    iframe {
      width: 100% !important;
    }
  }

  @media(max-width: ${breakpoints.tablet}px) {
    .tweet {
      flex: 1 0 50%;
    }
  }

  @media(max-width: ${breakpoints.mobile}px) {
    margin-bottom: 64px;
    .tweet {
      flex: 1 0 100%;
    }
  }
`;

const TwitterBlock = ({ content, items }) => {
  const { section_title } = content;

  return (
    <AnimatedBlock css={style}>
      <ContentWrap>
        <div className='twitter--top'>
          { section_title && (
            <SectionTitle title={section_title} animDelay={0} />
          )}
        </div>
        <div className='twitter--list-wrap'>
          <div className='twitter--scroll'>
            { items.map((d,i) => {
              // const del = 0.6 + i * 0.2;
              return (
                <div className='tweet' key={i}>
                  <TwitterTweetEmbed tweetId={d.tweet_id} options={{hideThread:true}}/>
                </div>
              )
            })}
          </div>
        </div>
      </ContentWrap>
    </AnimatedBlock>
  )
}

export default TwitterBlock;
