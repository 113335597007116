import React from 'react';
import { css } from '@emotion/react';
import classNames from 'classnames';
import AnimatedBlock, { animClass, animTypes } from '../AnimatedBlock';
import ContentWrap from '../ContentWrap';
import { fonts, p2r, breakpoints } from '../../styles';

const style = css`
  margin-bottom: 64px;
  
  .image--wrap {
    display: block;
    position: relative;
    width: 100%;
    
    .image--wrap-in {
      display: block;
      position: relative;
    }

    &.narrow {
      padding: 0 24px;
    }
  }

  .caption-text {
    ${fonts.HR};
    display: block;
    margin: 16px 0 0;
    font-size: ${p2r(12)};
    font-style: italic;
  }

  &.no-ratio {
    .image {
      width: 100%;
    }
  }

  &.ratio-wide {
    .image--wrap-in {
      overflow: hidden;
      &:before {
        content: '';
        position: relative;
        display: block;
        width: 100%;
        padding-top: 56.25%;
      }
      .image {
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        height: 100%;
        transform: translate(-50%, -50%);
        background-repeat: no-repeat;
        background-size: cover;
      }
      .video-wrap {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        > iframe {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  @media(max-width: ${breakpoints.tablet}px) {
    .image--wrap {
      &.narrow {
        padding: 0;
      }
    }
  }
`;

const FullImageBlock = ({ content }) => {
  const { is_video, aspect_ratio, caption_text, image, video_iframe_source, narrow_article_width } = content;

  const ratioClass = aspect_ratio === 'none' ? 'no-ratio' : 'ratio-wide';

  return (
    <AnimatedBlock css={style} className={classNames(ratioClass)}>
      <ContentWrap narrow={narrow_article_width}>
        <div className={classNames('image--wrap', {'narrow':narrow_article_width})}>
          <div className='image--wrap-in'>
            { !is_video && image && ratioClass === 'ratio-wide' && (
              <div className={classNames('image', animClass)} aria-label={image.alt} style={{backgroundImage:`url(${image.url})`}} data-anim-type={animTypes.SLIDE_UP_FULL} data-anim-delay={0} />
            )}
            { !is_video && image && ratioClass === 'no-ratio' && (
              <img className={classNames('image', animClass)} alt={image.alt} src={image.url} data-anim-type={animTypes.SLIDE_UP_FULL} data-anim-delay={0} />
            )}
            { is_video && video_iframe_source && (
              <div className={classNames('video-wrap', animClass)} data-anim-type={animTypes.SLIDE_UP_FULL} data-anim-delay={0}>
                <iframe title="video-frame" src={video_iframe_source} allow="autoplay; fullscreen; geolocation; encrypted-media" allowFullScreen webkitallowfullscreen='true' mozallowfullscreen='true' />
              </div>
            )}
          </div>
          { caption_text && (
            <div className='caption-text'><span>{caption_text}</span></div>
          )}
        </div>
      </ContentWrap>
    </AnimatedBlock>
  )
}

export default FullImageBlock;
