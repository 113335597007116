import React from 'react';
import { css } from '@emotion/react';
import { colors, breakpoints } from '../../styles';

const style = css`
  display: block;
  position: absolute;
  top: 30px;
  right: 90px;
  width: 30px;
  height: 30px;
  overflow: visible;
  pointer-events: auto;
  > span {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 30px;
    height: 4px;
    background-color: ${colors.wtRed};
    transform: translate(-50%, -50%) rotate(45deg);
    transition: 0.2s background-color ease-out;
    &:nth-of-type(2) {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }

  &.gallery-position {
    right: 0;
  }

  @media(hover: hover) {
    &:hover {
      > span {
        background-color: ${colors.wtBlack};
      }
    }
  }

  @media(max-width: ${breakpoints.tablet}px) {
    right: 50px;
    &.gallery-position {
      right: 0;
    }
  }

  @media(max-width: ${breakpoints.mobile}px) {
    right: 10px;
    top: 12px;
    &.gallery-position {
      right: 0;
    }
  }
`;

const CloseButton = (props) => {
  const { onClick, ...rest } = props;

  return (
    <button css={style} aria-label="close" onClick={onClick} {...rest}>
      <span />
      <span />
    </button>
  );
}

export default CloseButton;
