import React from 'react';
import { css } from '@emotion/react';
import { fonts, colors, p2r, breakpoints } from '../../styles';
import { Link } from 'gatsby';

const style = css`
  display: block;
  position: relative;
  margin: 0 0 16px;
  a {
    ${fonts.HR};
    color: ${colors.white};
    font-size: ${p2r(56)};
    text-decoration: none;
    letter-spacing: -0.07rem;
    line-height: 1;
    white-space: nowrap;
  }
  .arrow--wrap {
    display: inline-block;
    position: relative;
    height: 40px;
    width: 48px;
    overflow: hidden;
    margin-left: 24px;
  }
  .arrow {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    transform: translateX(-101%);
    transition: 0.15s transform ease-out;
  }

  @media(hover: hover) {
    &:hover {
      .arrow {
        transform: translateX(0);
      }
    }
  }

  @media(max-width: ${breakpoints.tablet}px) {
    a {
      font-size: ${p2r(32)};
      white-space: normal;
    }
    .arrow--wrap {
      display: none;
    }
  }
`;

const NavArrow = ({...rest}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="46.463" height="39.234" viewBox="0 0 46.463 39.234" {...rest}>
      <path id="Union_6" data-name="Union 6" d="M18285.479,13812.234v-34.657l-11.309,19.1-5.174-2.913,16.436-27.766.047.025v-.256h5.973v.42l.344-.194,16.436,27.766-5.178,2.918-11.6-19.605v35.159Z" transform="translate(13812.234 -18268.996) rotate(90)" fill="#de0c23"/>
    </svg>
  )
}

const MainMenuLink = ({label, link, onClick}) => {
  const {link_type, target, uid, url, document} = link;

  const type = link_type === 'Web' ? 'external' : document?.type === 'modal' ? 'modal' : 'internal';
  const linkRef = type === 'external' ? url : uid;

  return (
    <div css={style}>
      { type === 'internal' &&
        <Link to={`/${linkRef}`} onClick={onClick}>
          <span>{label}</span>
          <div className='arrow--wrap'>
            <NavArrow className='arrow' />
          </div>
        </Link>
      }
      { type === 'external' &&
        <a href={linkRef} target={target} rel='noopener noreferrer' onClick={onClick}>
          <span>{label}</span>
          <div className='arrow--wrap'>
            <NavArrow className='arrow' />
          </div>
        </a>
      }
    </div>
  )
}

export default MainMenuLink;
