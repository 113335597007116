import React, { useEffect, useRef } from 'react';
import { css } from '@emotion/react';
import ContentWrap from '../ContentWrap';

const style = css`
  display: block;
  position: relative;
  margin-bottom: 120px;

  .embed-wrap {
    display: block;
    width: 100%;
  }
`;

const CanadaHelpsBlock = ({ content }) => {
  const wrapRef = useRef();

  useEffect(() => {
    if (wrapRef.current) {
      const embedScript = document.createElement('script');
      embedScript.id = "ch_event_embed"
      embedScript.type = "text/javascript"
      embedScript.charset = "utf-8"
      embedScript.dataset.language = "en"
      embedScript.dataset.pageId = "20415"
      embedScript.dataset.rootUrl = "https://www.canadahelps.org"
      embedScript.src = "https://www.canadahelps.org/secure/js/ch_events_embed.js"

      wrapRef.current.appendChild(embedScript);
    }
  }, [wrapRef]);

  return (
    <div css={style}>
      <ContentWrap>
        <div className='embed-wrap' ref={wrapRef} />
      </ContentWrap>
    </div>
  )
}

export default CanadaHelpsBlock;
