import React from 'react';
import { css } from '@emotion/react';
import AnimatedBlock, { animTypes, animClass } from '../AnimatedBlock';
import ContentWrap from '../ContentWrap';
import BioThumb from '../ui/BioThumb';
import classNames from 'classnames';
import { breakpoints } from '../../styles';
import SectionTitle from '../ui/SectionTitle';

const style = (grid_size) => css`
  margin-bottom: 120px;

  .bio-list--top {
    display: block;
    h3 {
      margin-bottom: 24px;
    }
    .intro {
      max-width: 100%;
    }
  }

  .bio-list--list-wrap {
    margin-top: 48px;
    display: grid;
    position: relative;
    grid-template-columns: repeat(${grid_size}, 1fr);
    width: 100%;
    gap: 40px 24px;
  }

  @media(max-width: ${breakpoints.tablet}px) {
    margin-bottom: 100px;

    .bio-list--list-wrap {
      margin-top: 42px;
      grid-template-columns: repeat(2, 1fr);
      gap: 30px 20px;
    }
  }

  @media(max-width: ${breakpoints.mobile}px) {
    margin-bottom: 64px;
  }
`;

const BioListBlock = ({content, items, setModalContent}) => {
  const { theme, section_title, intro, title, grid_size = 3 } = content;
  
  return (
    <AnimatedBlock css={style(grid_size)} className={classNames(theme)}>
      <ContentWrap>
        <div className='bio-list--top'>
          { section_title && (
            <SectionTitle title={section_title} animDelay={0} />
          )}
          { title.text && (
            <h3 className={classNames(animClass)} data-anim-type={animTypes.SLIDE_UP} data-anim-delay={0.2} dangerouslySetInnerHTML={{__html:title.text}} />
          )}
          { intro.html && (
            <div className={classNames('intro', animClass)} data-anim-type={animTypes.SLIDE_UP} data-anim-delay={0.4} dangerouslySetInnerHTML={{__html:intro.html}}/>
          )}
        </div>
        <div className='bio-list--list-wrap'>
            {items.map((d,i) => {
              const del = 0.6 + i * 0.2;
              return (
                <BioThumb setModalContent={setModalContent} key={i} animDelay={del} {...d} />
              )
            })}
        </div>
      </ContentWrap>
    </AnimatedBlock>
  )
}

export default BioListBlock;
