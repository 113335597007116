import React from 'react';
import { css } from '@emotion/react';
import { fonts, p2r, breakpoints } from '../../styles';
import IconLinkArrow from '../icons/IconLinkArrow';
import { animClass, animTypes } from '../AnimatedBlock';
import { LinkCTA, ExternalCTA, ClickCTA } from './CTATypes';
import classNames from 'classnames';

const style = css`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
  text-decoration: none;

  .bio-image--wrap {
    display: block;
    position: relative;
    overflow: hidden;
    width: 100%;
    margin-bottom: 20px;
  }

  .bio-image {
    display: block;
    position: relative;
    overflow: hidden;
    width: 100%;
    &:before {
      content: '';
      display: block;
      position: relative;
      width: 100%;
      padding-top: 100%;
    }
    img {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      min-width: 100%;
      min-height: 100%;
      width: 100%;
      transform: translate(-50%, -50%);
      transition: 0.2s transform ease-out;
    }
  }

  .bio-label {
    ${fonts.HB};
    font-size: ${p2r(16)};
    text-decoration: none;
    .arrow {
      display: inline-block;
      position: relative;
      margin-left: 10px;
      transition: 0.5s transform cubic-bezier(0.175, 0.885, 0.32, 1.275);
      transform: translate(0, 2px);
    }
  }

  @media(hover: hover) {
    &:hover {
      .bio-image {
        img {
          transform: translate(-50%, -50%) scale(1.06);
          transition: 0.6s transform cubic-bezier(0.175, 0.885, 0.32, 1.275);
        }
      }
      .arrow {
        transform: translate(6px, 2px);
      }
    }
  }

  @media(max-width: ${breakpoints.tablet}px) {
    .bio-label {
      font-size: ${p2r(14)};
    }
  }
`;

const BioThumb = (props) => {
  const { setModalContent, cta_label, bio_image, cta_title, cta_description, cta_link, animDelay } = props;
  const { link_type, document } = cta_link;

  const type = link_type === 'Web' ? 'external' : document?.type === 'modal' ? 'modal' : 'internal';

  const Comp = type === 'modal' ? ClickCTA : (type === 'external' ? ExternalCTA : LinkCTA);

  const onClick = type === 'modal' ? () => {
    const modalData = cta_link.document.data || null;
    setModalContent(modalData);
  } : null;

  return (
    <Comp link={cta_link.url} target={cta_link.target} onClick={onClick} css={style}>
      <div className='bio-image--wrap'>
        <div className={classNames('bio-image', animClass)} data-anim-type={animTypes.SLIDE_RIGHT_FULL} data-anim-delay={animDelay}>
          <img src={bio_image.url} alt={bio_image.alt} />
        </div>
      </div>
      <div className={classNames('bio-label', animClass)} data-anim-type={animTypes.SLIDE_LEFT} data-anim-delay={animDelay + 0.1}>
        { cta_title && (
          <h5>{cta_title}</h5>
        )}
        { cta_description && (
          <p>{cta_description}</p>
        )}
        { cta_link && cta_label && (
          <div className='bio--cta'>
            <span>{cta_label}</span>
            <div className='arrow'>
              <IconLinkArrow />
            </div>
          </div>
        )}
      </div>
    </Comp>
  )
}

export default BioThumb;
