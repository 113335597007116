import React from 'react';
import { css } from '@emotion/react';
import AnimatedBlock, { animClass, animTypes } from '../AnimatedBlock';
import { p2r, breakpoints } from '../../styles';
import ContentWrap from '../ContentWrap';
import classNames from 'classnames';
import BasicCTA from '../ui/BasicCTA';
import IconQuote from '../icons/IconQuote';
import SectionTitle from '../ui/SectionTitle';

const style = css`
  margin-bottom: 120px;
  .feature-copy--wrap {
    h2 {
      margin-bottom: 24px;
      max-width: 616px;
    }
  }

  .feature-copy--content {
    display: flex;
    flex-direction: row;
    .content-image {
      flex: 1;
      > img {
        display: block;
        width: 100%;   
      }
    }
    .content-copy {
      flex: 1;
      padding-right: 32px;
      .intro p {
        font-size: ${p2r(20)};
        line-height: 1.45;
        max-width: 400px;
      }
      .paragaphs p {
        font-size: ${p2r(14)};
        line-height: 1.45;
        max-width: 400px;
      }
    }
  }

  .icon-quote {
    width: 32px;
    margin-bottom: 26px;
    overflow: hidden;
  }

  &.has-image {
    &.image-left { 
      .content-image {
        order: 1;
      }
      .content-copy {
        order: 2;
        padding-left: 64px;
      }
    }
    &.image-right {
      .feature-copy--content {
        .content-image {
          order: 1;
          margin-right: 0;
        }
        .content-copy {
          order: 0;
          padding-left: 0;
          padding-right: 64px;
        }
      }
    }
  }
  


  @media(max-width: ${breakpoints.tablet}px) {
    .feature-copy--content {
      flex-direction: column;
      .content-image {
        order: 2;
      }
      .content-copy {
        order: 1;
        margin-bottom: 32px;
        padding: 0;
        p {
          font-size: ${p2r(18)};
        }
      }
    }

    .icon-quote {
      margin-bottom: 16px;
    }

    &.has-image {
      &.image-right,
      &.image-left { 
        .content-image {
          order: 2;
        }
        .content-copy {
          order: 1;
          padding-left: 0;
        }
      }
    }
  }

  @media(max-width: ${breakpoints.mobile}px) {
    margin-bottom: 64px;  
  }
`;

const FeatureCopyBlock = ({ content, setModalContent }) => {
  const { intro, paragraphs, title, section_title, cta_label, is_quote, theme, cta_link, image } = content;

  const hasImage = (theme === 'image-left' || theme === 'image-right') && image;

  return (
    <AnimatedBlock css={style} className={classNames(theme, {'has-image': hasImage})}>
      <ContentWrap>
        <div className='feature-copy--wrap'>
          { section_title && (
            <SectionTitle title={section_title} animDelay={0} />
          )}
          <div className={classNames('feature-copy--content')}>
            <div className='content-copy'>
              { is_quote && (
                <IconQuote animDelay={0.5} />
              )}
              { title && (
                <h2 className={classNames(animClass)} data-anim-type={animTypes.SLIDE_UP} data-anim-delay={0.2} dangerouslySetInnerHTML={{__html:title.text}} />
              )}
              { intro && (
                <div className={classNames('intro', animClass)} data-anim-type={animTypes.SLIDE_UP} data-anim-delay={0.6} dangerouslySetInnerHTML={{__html:intro.html}}/>
              )}
              { paragraphs && (
                <div className={classNames('paragaphs', animClass)} data-anim-type={animTypes.SLIDE_UP} data-anim-delay={0.6} dangerouslySetInnerHTML={{__html:paragraphs.html}}/>
              )}
              { cta_link && cta_label && (
                <BasicCTA 
                  className={classNames(animClass)}
                  data-anim-type={animTypes.SLIDE_LEFT}
                  data-anim-delay={1}
                  label={cta_label} 
                  link={cta_link} 
                  setModalContent={setModalContent}
                />
              )}
            </div>
            { hasImage && (
              <div className={classNames('content-image', animClass)} data-anim-type={animTypes.FADE} data-anim-delay={0.4}>
                <img src={image.url} alt={image.alt} />
              </div>
            )}
          </div>
        </div>
      </ContentWrap>
    </AnimatedBlock>
  )
}

export default FeatureCopyBlock;
