import React, { useEffect} from "react"
import { css } from "@emotion/react"
import PropTypes from "prop-types"
import TopNav from "./nav/TopNav";
import { graphql, Script, useStaticQuery } from 'gatsby';

const style = css`
  display: block;
  position: relative;
  width: 100%;
  padding: 0;
  margin: 0;
`

const Layout = (props) => {
  const { children, uid, scrollPos, windowHeight, hideNavLogo, setNavData } = props;
  const globalData = useStaticQuery(graphql`
    fragment Modal on PrismicModal {
      type
      data {
        theme
        title {
          html
          text
        }
        paragraphs {
          html
          text
        }
        intro {
          html
          text
        }
        image {
          alt
          url
        }
        cta_label
        cta_link {
          url
          target
          link_type
          uid
        }
      }
    }

    query navData {
      allPrismicGlobal {
        edges {
          node {
            _previewable
            data {
              contact_address
              contact_email
              contact_name
              contact_title
              label_contact
              label_social
              link_wtoc_label
              social_list {
                  social_type
                  social_link {
                    url
                    target
                    link_type
                    uid
                    document {
                      ...Modal
                    }
                  }
                }
                top_nav_list {
                  label
                  link {
                    url
                    target
                    link_type
                    uid
                    document {
                      ...Modal
                    }
                  }
                }
                logo_wtoc {
                  alt
                  url
                }
                logo_wtoc_white {
                  alt
                  url
                }
                logo_wia {
                  alt
                  url
                }
                link_wtoc {
                  url
                  target
                  link_type
                  uid
                  document {
                    ...Modal
                  }
                }
                banner_copy {
                  html
                  text
                }
                banner_cta_label
                banner_cta_link {
                  url
                  target
                  link_type
                  uid
                  document {
                    ...Modal
                  }
                }
                banner_logo {
                  alt
                  url
                }
                show_popup_on_page_load
                show_popup_daily
                popup_id
                page_load_popup_title {
                  html
                  text
                }
                page_load_popup_body {
                  html
                  text
                }
                page_load_popup_cta_label
                page_load_popup_cta_link {
                  url
                  target
                  link_type
                  uid
                  document {
                    ...Modal
                  }
                }
                page_load_popup_cta_tracking
            }
          }
        }
      }
    }
  `);

  const navData = globalData.allPrismicGlobal.edges[0].node.data;

  useEffect(() => {
    setNavData(navData);
  }, [navData, setNavData]);

  return (
    <main css={style}>
      <Script dangerouslySetInnerHTML={{__html:`
        !function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '639758288018783');
        fbq('track', 'PageView');          
      `}}/>
      <noscript
        dangerouslySetInnerHTML={{
          __html: `
          <img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=639758288018783&ev=PageView&noscript=1"/>
            `,
        }}
      ></noscript>
      <div className="body-wrap">
        <TopNav 
          navData={navData}
          currentPage={uid} 
          scrollPos={scrollPos}
          windowHeight={windowHeight}
          hideNavLogo={hideNavLogo}
        />
        {children}
      </div>
    </main>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
