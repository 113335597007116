const breakpoints = {
  small: 360,
  mobile: 640,
  tablet: 960,
  desktop: 1160,
  mid: 1280,
  medium: 1440,
  large: 1920,

  maxwidthcontent: 1440,
  maxwidthnarrow: 1280,
}

export default breakpoints;
