import React, { useEffect, useRef } from 'react';
import { css } from '@emotion/react';
import { colors, breakpoints, fonts, p2r, scrollbars } from '../../styles';
import { trackEvent } from '../../utils';
import ContentWrap from '../ContentWrap';
import CloseButton from '../ui/CloseButton';
import classNames from 'classnames';
import gsap from 'gsap';
import BasicCTA from '../ui/BasicCTA';

const style = css`
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 200;
  overflow: hidden;

  .modal--scrim {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${colors.wtBlack};
    opacity: 0;
  }

  .modal--wrap {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    overflow: hidden;
  }

  .modal--container {
    display: flex;
    position: absolute;
    max-height: calc(100vh - 100px);
    width: calc(100% - 240px);
    max-width: ${breakpoints.maxwidthcontent}px;
  }

  .modal--window {
    display: block;
    position: relative;
    background-color: ${colors.bgGrey};
    width: 100%;
    padding: 95px 0;
    pointer-events: auto;
    overflow-y: auto;
    ${scrollbars.v};
  }

  .modal--content {
    .grid-wrap {
      display: grid;
      grid-template-columns: 1fr;
    }
    h2 {
      max-width: 520px;
      margin-bottom: 35px;
    }
    .intro {
      margin-bottom: 30px;
    }
    .body {
      margin-bottom: 30px;
    }
    .intro,
    .intro p {
      ${fonts.HR};
      font-size: ${p2r(20)};
      line-height: 1.45;
    }
    .image {
      position: relative;
      margin-bottom: 50px;
      > img {
        display: block;
        max-width: 100%;
        max-height: 100%;
      }
    }
    .sep {
      display: block;
      position: relative;
      width: 100%;
      height: 1px;
      background-color: ${colors.wtBlack};
      opacity: 0.2;
      margin: 20px 0 28px;
    }
  }

  &.page-load {
    .modal--content {
      p {
        font-size: ${p2r(20)};
        line-height: 1.45;
      }
    }
  }

  &.image-gallery {
    .modal--content-wrap {
      padding: 0;
    }
    .modal--window {
      background-color: transparent;
      width: 100%;
    }

    .modal--content {
      .grid-wrap {
        display: flex;
      }
    }

    .full-image--wrap {
      display: block;
      width: 100%;
      > img {
        width: 100%;
        height: 100%;
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
      }
    }
  }

  &.bio,
  &.book {
    .grid-wrap {
      grid-template-rows: auto;
      grid-template-columns: auto;
      grid-template-areas: 
        "img header"
        "img txt"
        "img ."
    }
    
    .image {
      grid-area: img;
      margin-bottom: 0;
    }

    .header {
      grid-area: header;
      padding-left: 24px;
    }
    .txt {
      grid-area: txt;
      padding-left: 24px;
    }
  }

  @media(max-width: ${breakpoints.desktop}px) {
    .modal--container {
      width: 100%;
      max-width: calc(100% - 160px);
    }
  }

  @media(max-width: ${breakpoints.tablet}px) {
    .modal--container {
      max-height: calc(100vh - 80px);
      width: 100%;
      max-width: calc(100% - 64px);
    }
    .modal--window {
      padding: 75px 0;
    }

    .modal--content {
      .intro,
      .intro p {
        font-size: ${p2r(18)};
      }
    }

    &.bio {
      .modal--container {
        max-width: calc(100% - 32px);
      }
    }
  }

  @media(max-width: ${breakpoints.tablet}px) {
    .modal--container {
      max-height: calc(100vh - 40px);
      max-width: calc(100% - 24px);
    }
    &.bio {
      .modal--container {
        max-width: calc(100% - 24px);
      }
    }
  }
`;

const Modal = ({ modalContent, setModalContent }) => {
  const { theme, galleryImage, title, intro, image, paragraphs, cta_label, cta_link, popup_id, cta_tracking } = modalContent;
  const ref = useRef();
  const scrimRef = useRef();

  const isGallery = galleryImage && theme === 'image-gallery';

  const closeModal = () => {
    trackEvent('close_modal', title.text);
    gsap.to(scrimRef.current, 0.3, { alpha: 0 });
    gsap.to(ref.current, 0.3, { y: '-100%', ease: 'strong.in', onComplete: () => setModalContent(null)});
  }

  const handleCTAClick = () => {
    if (cta_tracking) {
      if (window.fbq) {
        window.fbq('track', cta_tracking);
      }  
    }
    setModalContent(null);
  }

  useEffect(() => {
    if (ref?.current) {
      trackEvent('open_modal', title.text);
      gsap.fromTo(scrimRef.current, 0.3, { alpha: 0}, { alpha: 0.75 });
      gsap.fromTo(ref.current, 1, { y: '-100%' }, { y: 0, ease: 'strong.out' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, scrimRef]);

  return (
    <div css={style} className={classNames(theme)}>
      <div className='modal--scrim' onClick={closeModal} ref={scrimRef} />
      <div className='modal--wrap' ref={ref}>
        <div className='modal--container'>
          <div className='modal--window'>
            <div className='modal--content'>
              <ContentWrap className='modal--content-wrap'>
                <div className='grid-wrap'>
                  { isGallery && (
                    <div className='full-image--wrap'>
                      <img className='gallery-image' src={galleryImage.url} alt={galleryImage.alt} />
                    </div>
                  )}
                  { title && (
                    <h2 className='header' dangerouslySetInnerHTML={{__html:title.text}} />
                  )}
                  { image && (
                    <div className='image'>
                      <img src={image.url} alt={image.alt}/>
                    </div>
                  )}
                  <div className='txt'>
                    { intro && (
                      <div className='intro' dangerouslySetInnerHTML={{__html:intro.html}} />
                    )}
                    { paragraphs && (
                      <div className='body' dangerouslySetInnerHTML={{__html:paragraphs.html}} />
                    )}
                    { cta_link && cta_label && (
                      <BasicCTA 
                        className={popup_id}
                        label={cta_label} 
                        link={cta_link}
                        onClick={handleCTAClick}
                        setModalContent={setModalContent}
                      />
                    )}
                  </div>
                </div>
              </ContentWrap>
            </div>
          </div>
          <CloseButton onClick={closeModal} className={classNames({'gallery-position': isGallery})} />
        </div>
      </div>
    </div>
  )
}

export default Modal;
