import React from 'react';
import { css } from '@emotion/react';
import AnimatedBlock, { animClass, animTypes } from '../AnimatedBlock';
import { breakpoints, p2r } from '../../styles';
import ContentWrap from '../ContentWrap';
import classNames from 'classnames';
import BasicCTA from '../ui/BasicCTA';
import SectionTitle from '../ui/SectionTitle';

const style = css`
  margin-bottom: 120px;

  .video-copy--wrap {
    h2 {
      margin-bottom: 24px;
      max-width: 616px;
    }
  }

  .video-copy--layout {
    .content-copy {
      flex: 1;
      margin-bottom: 50px;
      .intro p {
        font-size: ${p2r(20)};
        line-height: 1.45;
      }
      .paragaphs p {
        font-size: ${p2r(14)};
        line-height: 1.45;
      }
    }

    .content-video {
      display: block;
      width: 100%;
      position: relative;
      overflow: hidden;
      &:before {
        content: '';
        position: relative;
        display: block;
        width: 100%;
        padding-top: 56.25%;
      }
      .video-wrap {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        > iframe {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  &.video-right {
    .video-copy--layout {
      display: flex;
      flex-direction: row;
      .content-copy {
        min-width: 320px;
        padding-right: 32px;
      }
      .content-video {
        flex: 0 1 auto;
      }
    }
  }

  @media(max-width: ${breakpoints.tablet}px) {
    margin-bottom: 64px;
   
    &.video-right {
      .video-copy--layout {
        flex-direction: column;
        .content-copy {
          min-width: 320px;
          padding-right: 32px;
          margin-bottom: 24px;
        }
      }
    }
  }
`;

const VideoCopyBlock = ({ content, setModalContent }) => {
  const { theme, intro, paragraphs, title, section_title, video_iframe_source, cta_label, cta_link } = content;

  return (
    <AnimatedBlock css={style} className={classNames(theme)}>
      <ContentWrap>
        <div className='video-copy--wrap'>
          { section_title && (
            <SectionTitle title={section_title} animDelay={0} />
          )}
          <div className='video-copy--layout'>
            <div className='content-copy'>
              { title && title.text && (
                <h2 className={classNames(animClass)} data-anim-type={animTypes.SLIDE_UP} data-anim-delay={0.2} dangerouslySetInnerHTML={{__html:title.text}} />
              )}
              { intro && (
                <div className={classNames('intro', animClass)} data-anim-type={animTypes.SLIDE_UP} data-anim-delay={0.6} dangerouslySetInnerHTML={{__html:intro.html}}/>
              )}
              { paragraphs && (
                <div className={classNames('paragaphs', animClass)} data-anim-type={animTypes.SLIDE_UP} data-anim-delay={0.6} dangerouslySetInnerHTML={{__html:paragraphs.html}}/>
              )}
              { cta_link && cta_label && (
                <BasicCTA 
                  className={classNames(animClass)}
                  data-anim-type={animTypes.SLIDE_LEFT}
                  data-anim-delay={1}
                  label={cta_label} 
                  link={cta_link} 
                  setModalContent={setModalContent}
                />
              )}
            </div>
            { video_iframe_source && (
              <div className='content-video'>
                <div className={classNames('video-wrap', animClass)} data-anim-type={animTypes.SLIDE_UP_FULL} data-anim-delay={0}>
                  <iframe src={video_iframe_source} allow="autoplay; fullscreen; geolocation; encrypted-media" allowFullScreen webkitallowfullscreen='true' mozallowfullscreen='true' />
                </div>
              </div>
            )}
          </div>
        </div>
      </ContentWrap>
    </AnimatedBlock>
  )
}

export default VideoCopyBlock;
