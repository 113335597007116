import React from 'react';
import { css } from '@emotion/react';
import { fonts, p2r, breakpoints, colors } from '../../styles';
import { LinkCTA, ExternalCTA, NoCTA, ClickCTA } from './CTATypes';
import IconLinkArrow from '../icons/IconLinkArrow';
import classNames from 'classnames';

const style = css`
  display: block;
  position: relative;
  white-space: nowrap;
  ${fonts.HB};
  font-size: ${p2r(16)};
  text-decoration: none;
  pointer-events: auto;

  .arrow {
    display: inline-block;
    position: relative;
    margin-left: 10px;
    transition: 0.5s transform cubic-bezier(0.175, 0.885, 0.32, 1.275);
    transform: translate(0, 2px);
  }

  .btn-wrap {
    display: flex;
    flex-direction: row;

    &.btn-back {
      .lbl {
        order: 1;
      }
      .arrow {
        order: 0;
        margin-left: 0;
        margin-right: 10px;
        transform: translate(0, -2px) rotate(180deg);
      }
    }
  }

  &.white-cta {
    color: ${colors.white};
  }

  @media(hover: hover) {
    &:hover {
      .arrow {
        transform: translate(6px, 2px);
      }
      .btn-wrap {
        &.btn-back {
          .arrow {
            transform: translate(-6px, -2px) rotate(180deg);
          }
        }
      }
    }
  }

  @media(max-width: ${breakpoints.tablet}px) {
    font-size: ${p2r(14)};
  }
`;

const BasicCTA = (props) => {
  const { label, link, onClick, setModalContent, ...rest } = props;
  const { link_type, document } = link;

  const type = link_type === 'Any' ? 'void' : link_type === 'Web' ? 'external' : document?.type === 'modal' ? 'modal' : 'internal';

  const linkRef = type === 'external' ? link.url : link.uid;

  const Comp = type === 'void' ? NoCTA : type === 'modal' || !link ? ClickCTA : (type === 'external' ? ExternalCTA : LinkCTA);
  
  const handleOnClick = type === 'modal' ? () => {
    const modalData = link?.document.data || null;
    setModalContent(modalData);
  } : null;

  return (
    <Comp link={linkRef} onClick={onClick || handleOnClick} target={link?.target} css={style} {...rest}>
      <div className={classNames('btn-wrap', {'btn-back': (type === 'back')})}>
        <span className='lbl'>{label}</span>
        <div className='arrow'>
          <IconLinkArrow />
        </div>
      </div>
    </Comp>
  )
}

export default BasicCTA;
