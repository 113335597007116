import React from 'react';
import { css } from '@emotion/react';
import { breakpoints, p2r, colors } from '../../styles';
import IconMagnify from '../icons/IconMagnify';
import { animClass, animTypes } from '../AnimatedBlock';
import classNames from 'classnames';

const style = css`
  display: block;
  position: relative;
  padding-right: 20px;
  flex: 1 0 12.5%;
  max-width: 12.5%;
  .caption {
    display: block;
    font-size: ${p2r(12)};
    font-style: italic;
    margin-bottom: 30px;
  }
  .thumb {
    display: block;
    position: relative;
    width: 100%;
    margin-bottom: 0;
    overflow: hidden;
    transition: 0.2s border ease-out;
    .icon {
      display: block;
      position: absolute;
      bottom: 0;
      right: 0;
      width: 32px;
      height: 32px;
      background-color: ${colors.wtBlack};
      pointer-events: none;
      > svg {
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 16px;
        height: 16px;
        transform: translate(-50%, -50%);
      }
    }
    .thumb-image {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center;
      transition: 0.2s transform ease-out;
    }
    &:before {
      content: '';
      display: block;
      position: relative;
      padding-top: 100%;
      width: 100%;
      background-color: red;
    }
    &.active {
      border: 4px solid ${colors.wtRed};
    }
  }

  @media(hover: hover) {
    .thumb {
      &:hover {
        .thumb-image {
          transform: scale(1.06);
          transition: 0.6s transform cubic-bezier(0.175, 0.885, 0.32, 1.275);
        }
      }
    }
  }

  @media(max-width: ${breakpoints.tablet}px) {
    flex: 1 0 20%;
    max-width: 20%;
  }

  @media(max-width: ${breakpoints.mobile}px) {
    flex: 1 0 25%;
    max-width: 25%;
  }
`;

const GalleryThumb = (props) => {
  const { caption, thumb, imageId, setCurrentImage, isCurrent, animDelay } = props;

  const imgStyle = {
    backgroundImage: `url(${thumb.url})`
  }

  const onClick = () => {
    setCurrentImage(imageId);
  };

  return (
    <div css={style}>
      <button className={classNames('thumb', animClass, {'active': isCurrent})} onClick={onClick} data-anim-type={animTypes.FADE} data-anim-delay={animDelay}>
        <div className='thumb-image' style={imgStyle} aria-label={thumb.alt} />
        <div className='icon'><IconMagnify/></div>
      </button>
      { false && (
        <div className={classNames('caption', animClass)} data-anim-type={animTypes.SLIDE_LEFT} data-anim-delay={animDelay + 0.1}>
          <span>{caption}</span>
        </div>
      )}
    </div>
  )
}

export default GalleryThumb;
