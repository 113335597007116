import React from 'react';

const IconLinkArrow = ({...rest}) => {

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="19.619" height="17.232" viewBox="0 0 19.619 17.232" {...rest}>
      <path d="M18276.236,13785.618v-14.4l-4.967,8.6-2.271-1.312,7.219-12.5.02.011V13766h2.625v.088l.15-.088,7.219,12.5-2.271,1.313-5.1-8.829v14.635Z" transform="translate(13785.618 -18268.998) rotate(90)" fill="#de0c23"/>
    </svg>
  )
}

export default IconLinkArrow;
