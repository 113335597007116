import React from 'react';
import { css } from '@emotion/react';
import ContentWrap from '../ContentWrap';
import BasicCTA from '../ui/BasicCTA';
import { colors, p2r, breakpoints } from '../../styles';

const style = css`
  display: block;
  padding: 60px 0;
  background-color: ${colors.bannerBlack};
  color: ${colors.white};
  .banner--wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .banner--content {
    display: block;
    flex: 1;
    padding-right: 100px;
    max-width: 850px;
    p {
      font-size: ${p2r(20)};
      line-height: 1.45;
    }
  }
  .wt--logo {
    height: 100px;
    > img {
      display: block;
      height: 100%;
    }
  }

  @media(max-width: ${breakpoints.tablet}px) {
    .banner--wrap {
      align-items: flex-start;
    }
    .banner--content {
      padding-right: 24px;
      p {
        font-size: ${p2r(18)};
      }
    }
    .wt--logo {
      height: 75px;
    }
  }
`;

const FooterBanner = (props) => {
  const { banner_copy, banner_cta_label, banner_cta_link, banner_logo } = props;

  return (
    <div css={style}>
      <ContentWrap>
        <div className='banner--wrap'>
          <div className='banner--content'>
            <div dangerouslySetInnerHTML={{__html: banner_copy.html}}/>
            <BasicCTA
              className='white-cta'
              label={banner_cta_label} 
              link={banner_cta_link}
            />
          </div>
          <div className='banner--img'>
            <div className='wt--logo'>
              <img src={banner_logo.url} alt={banner_logo.alt} />
            </div>
          </div>
        </div>
      </ContentWrap>
    </div>
  )
}

export default FooterBanner;
