import ArticleHeroBlock from './ArticleHeroBlock';
import AwardHistoryBlock from './AwardHistoryBlock';
import BasicCopyBlock from './BasicCopyBlock';
import BioListBlock from './BioListBlock';
import BookListBlock from './BookListBlock';
import CanadaHelpsBlock from './CanadaHelpsBlock';
import DoubleColumnCopyBlock from './DoubleColumnCopyBlock';
import ExploreBlock from './ExploreBlock';
import FeatureCopyBlock from './FeatureCopyBlock';
import FullImageBlock from './FullImageBlock';
import ImageGalleryBlock from './ImageGalleryBlock';
import ListCopyBlock from './ListCopyBlock';
import MapBlock from './MapBlock';
import NoticeBlock from './NoticeBlock';
import SignupFormBlock from './SignupFormBlock';
import TwitterBlock from './TwitterBlock';
import VideoCopyBlock from './VideoCopyBlock';

export default {
  ArticleHeroBlock,
  AwardHistoryBlock,
  BasicCopyBlock,
  BioListBlock,
  BookListBlock,
  CanadaHelpsBlock,
  DoubleColumnCopyBlock,
  ExploreBlock,
  FeatureCopyBlock,
  FullImageBlock,
  ImageGalleryBlock,
  ListCopyBlock,
  MapBlock,
  NoticeBlock,
  SignupFormBlock,
  TwitterBlock,
  VideoCopyBlock,
}
