import React, { useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import Seo from '../components/seo';
import pageStyle from '../styles/page-styles';
import Footer from '../components/nav/Footer';
import FooterBanner from '../components/nav/FooterBanner'; 
import Modal from '../components/modal/Modal';
import Blocks from '../components/blocks';
import { hasWindow } from '../utils';


import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';

const Page = ({ data, pageContext }) => {
  const { uid } = pageContext;
  const { meta_title, meta_description, body, show_footer_banner, hide_nav_logo } = data.prismicPage.data;
  
  // const navData = data.allPrismicGlobal ? data.allPrismicGlobal.edges[0].node.data : null;
  const [ navData, setNavData ] = useState(null);
  const [ modalContent, setModalContent ] = useState(null);
  const [ windowHeight, setWindowHeight ] = useState(0);
  const [ scrollPos, setScrollPos ] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window?.innerHeight);
    }
    if (hasWindow()) {
      window.addEventListener('resize', handleResize);
      handleResize();
    }
    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, [setWindowHeight]);

  useEffect(() => {
    const handleScroll = (e) => {
      if (hasWindow()) {
        let scTop = window.scrollY || window.pageYOffset;
        setScrollPos(scTop);
      }
    }
    if (hasWindow()) {
      window.addEventListener('scroll', handleScroll);
      handleScroll();
    }
    return () => {
      window.removeEventListener('scroll', handleScroll);
    }
  }, [setScrollPos]);

  // check to see if we should show on-page-load modal
  useEffect(() => {
    if (navData) {
      const { page_load_popup_cta_tracking, show_popup_on_page_load, show_popup_daily, popup_id, page_load_popup_title, page_load_popup_body, page_load_popup_cta_label, page_load_popup_cta_link} = navData;
      const lastSeenDate = JSON.parse(localStorage.getItem(popup_id));
      
      const fullDate = new Date();
      const dateString = `${fullDate.getFullYear()}_${fullDate.getMonth()}_${fullDate.getDate()}`;
      
      const hasSeenToday = dateString === lastSeenDate;

      if (hasWindow() && show_popup_daily) {
        window.onbeforeunload = () => {
          localStorage.clear();
        }
      }
      
      if (show_popup_on_page_load && !hasSeenToday) {
        // if (show_popup_on_page_load) {
        const modalData = {
          theme: 'page-load',
          title: page_load_popup_title,
          paragraphs: page_load_popup_body,
          cta_label: page_load_popup_cta_label,
          cta_link: page_load_popup_cta_link,
          cta_tracking: page_load_popup_cta_tracking,
          popup_id: popup_id
        }
        
        const showPopup = () => {
          setModalContent(modalData);
          localStorage.setItem(popup_id, JSON.stringify(dateString));
        }

        setTimeout(showPopup, 3000);
      }
    }
  }, [navData, setModalContent]);

  return (
    <Layout uid={uid} scrollPos={scrollPos} windowHeight={windowHeight} hideNavLogo={hide_nav_logo} setNavData={setNavData}>
      <Seo title={meta_title} description={meta_description} />
      
      <div id={uid} css={pageStyle} key={`id_${uid}`}>
        { body.map((d,i) => {
          const dat = d.primary;
          const items = d.items || [];
          const Component = Blocks[dat.component_name];
          const idname = dat.anchor_id;
          return (
            <div id={idname} key={i}>
              <Component content={dat} items={items} setModalContent={setModalContent}/>
            </div>
          )
        })}
        <footer className='footer--wrap'>
          { show_footer_banner && navData && (
            <FooterBanner {...navData} />
          )}
          { navData && (
            <Footer {...navData} />
          )}
        </footer>
        { modalContent && (
          <Modal modalContent={modalContent} setModalContent={setModalContent} />
        )}
      </div>
    </Layout>
  )
}

export default withPrismicPreview(Page);

export const query = graphql`
  fragment Modal on PrismicModal {
    type
    data {
      theme
      title {
        html
        text
      }
      paragraphs {
        html
        text
      }
      intro {
        html
        text
      }
      image {
        alt
        url
      }
      cta_label
      cta_link {
        url
        target
        link_type
        uid
      }
    }
  }

  fragment HomeHero on PrismicPageDataBodyHomeHero {
    primary {
      component_name
      theme
      block_hero_title {
        html
        text
      }
      hero_desc {
        html
        text
      }
      scroll_to_anchor
      show_logo
    }
  }

  fragment CopyBlock on PrismicPageDataBodyCopyBlock {
    primary {
      component_name
      theme
      anchor_id
      is_quote
      full_bleed_image
      narrow_article_width
      show_borders
      section_title
      cta_label
      cta_link {
        url
        target
        link_type
        uid
        document {
          ...Modal
        }
      }
      title {
        html
        text
      }
      intro {
        html
        text
      }
      paragraphs {
        html
        text
      }
      image {
        alt
        url
      }
      attrib
    }
  }

  fragment BioList on PrismicPageDataBodyBioListBlock {
    items {
      cta_label
      cta_title
      cta_description
      bio_image {
        alt
        url
      }
      cta_link {
        url
        target
        link_type
        uid
        document {
          ...Modal
        }
      }
    }
    primary {
      component_name
      theme
      anchor_id
      section_title
      grid_size
      title {
        html
        text
      }
      intro {
        html
        text
      }
    }
  }

  fragment BookList on PrismicPageDataBodyBookList {
    items {
      book_description
      book_image {
        alt
        url
      }
      book_title
      cta_label
      cta_link {
        url
        target
        link_type
        uid
        document {
          ...Modal
        }
      }
    }
    primary {
      anchor_id
      section_title
      title {
        html
        text
      }
      component_name
    }
  }

  fragment AwardHistory on PrismicPageDataBodyAwardHistory {
    items {
      award_year
      link_award_label
      link_event_label
      title {
        html
        text
      }
      description {
        html
        text
      }
      image {
        alt
        url
      }
      link_award {
        uid
        type
      }
      link_event {
        uid
        type
      }
    }
    primary {
      anchor_id
      component_name
      section_title
    }
  }

  fragment Explore on PrismicPageDataBodyExploreBlock {
    primary {
      component_name
      anchor_id
      section_title
    }
    items {
      override_cta
      override_description
      override_title
      override_image {
        alt
        url
      }
      linked_page {
        uid
        document {
          ... on PrismicPage {
            data {
              link_title
              link_description
              link_cta
              link_image {
                alt
                url
              }
            }
          }
        }
      }
    }
  }

  fragment FullImage on PrismicPageDataBodyFullImageVideoBlock {
    primary {
      anchor_id
      aspect_ratio
      caption_text
      component_name
      is_video
      video_iframe_source
      narrow_article_width
      image {
        alt
        url
      }
    }
  }

  fragment Twitter on PrismicPageDataBodyTwitterBlock {
    primary {
      anchor_id
      component_name
      section_title
    }
    items {
      tweet_id
    }
  }

  fragment Video on PrismicPageDataBodyVideoCopyBlock {
    primary {
      anchor_id
      component_name
      cta_label
      theme
      section_title
      video_iframe_source
      cta_link {
        url
        target
        link_type
        uid
        document {
          ...Modal
        }
      }
      intro {
        html
        text
      }
      paragraphs {
        html
        text
      }
      title {
        html
        text
      }
    }
  }

  fragment NoticeCallout on PrismicPageDataBodyNoticeCallout {
    primary {
      anchor_id
      component_name
      cta_label
      narrow_article_width
      title {
        html
        text
      }
      paragraphs {
        html
        text
      }
      icon {
        alt
        url
      }
      cta_link {
        url
        target
        link_type
        uid
        document {
          ...Modal
        }
      }
    }
  }

  fragment Map on PrismicPageDataBodyMapBlock {
    primary {
      anchor_id
      caption_text
      component_name
      narrow_article_width
      section_title
      paragraphs {
        html
        text
      }
      intro {
        html
        text
      }
      map_iframe_src
    }
  }

  fragment ImageGallery on PrismicPageDataBodyImageGallery {
    primary {
      anchor_id
      component_name
      narrow_article_width
      section_title
      description {
        html
        text
      }
    }
    items {
      caption
      thumb {
        alt
        url
      }
      full_image {
        alt
        url
      }
    }
  }

  fragment CanadaHelps on PrismicPageDataBodyCanadahelpsembed {
    primary {
      anchor_id
      component_name
    }
  }

  fragment SignupForm on PrismicPageDataBodySignupForm {
    primary {
      anchor_id
      component_name
      form_id
    }
  }

  query PageBySlug($uid: String!) {
    prismicPage(uid: { eq: $uid }) {
      _previewable
      uid
      data {
        meta_description
        meta_title
        show_footer_banner
        hide_nav_logo
        body {
          ...HomeHero
          ...CopyBlock
          ...BioList
          ...BookList
          ...AwardHistory
          ...Explore
          ...FullImage
          ...Twitter
          ...Video
          ...NoticeCallout
          ...Map
          ...ImageGallery
          ...CanadaHelps
          ...SignupForm
        }
      }
    }
    
  }
`;
