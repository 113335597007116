import React from 'react';

const IconMagnify = ({...rest}) => {

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.96 19.91" {...rest}>
      <path d="m18.75,19.91l-7.24-7.24c-.57.49-1.22.87-1.93,1.12-3.69,1.36-7.78-.53-9.14-4.21C-.91,5.89.98,1.8,4.66.44c3.69-1.36,7.78.53,9.14,4.21.57,1.54.59,3.22.05,4.77-.27.75-.66,1.45-1.16,2.07l7.27,7.21s-1.21,1.21-1.21,1.21Zm-11.54-7.38c1.43.02,2.81-.55,3.8-1.58,2.09-2.11,2.09-5.51,0-7.63-.99-1.03-2.37-1.61-3.8-1.58-2.98-.11-5.49,2.21-5.6,5.19-.11,2.98,2.21,5.49,5.19,5.6h.42-.01Zm-.85-2.34v-2.26h-2.26v-1.65h2.26v-2.23h1.65v2.23h2.23v1.65h-2.23v2.25h-1.65,0Z" fill="#ffffff"/>
    </svg>
  )
}

export default IconMagnify;
