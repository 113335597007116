import React from 'react';
import { css } from '@emotion/react';
import { breakpoints } from '../styles';
import classNames from 'classnames';

const style = css`
  display: block;
  position: relative;
  max-width: ${breakpoints.maxwidthcontent}px;
  width: 100%;
  margin: 0 auto;
  padding: 0 120px;

  &.narrow {
    max-width: ${breakpoints.maxwidthnarrow}px;
  }

  @media(max-width: ${breakpoints.desktop}px) {
    padding: 0 80px;
  }

  @media(max-width: ${breakpoints.tablet}px) {
    padding: 0 64px;
  }

  @media(max-width: ${breakpoints.mobile}px) {
    padding: 0 32px;
  }
`;

const ContentWrap = ({ children, narrow, className, ...rest }) => {
  return (
    <div css={style} className={classNames(className, {'narrow':narrow})} {...rest}>
      {children}
    </div>
  )
}

export default ContentWrap;
