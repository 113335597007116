import { css } from '@emotion/react'

const FontHaas = css`
  font-family: 'neue-haas-grotesk-display', sans-serif;
`;

const fonts = {
  HR: css`
    ${FontHaas};
    font-weight: 500;
  `,
  HB: css`
    ${FontHaas};
    font-weight: 700;
  `,
}

export default fonts;
